header {
    @extend %absolute;
    left: 0;
    right: 0;
    z-index: 4;

    nav {
        padding: 20px 30px 20px 0;
        @include respond-to(738) {
            margin-left: auto;
        }
        a,
        button,
        span {
            margin-left: 30px;
        }
    }
    div {
        @extend %font-regular;
        color: $font-color-secondary;
    }

    button {
        background-color: Transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
    }

    .shadow {
        box-shadow: 0 1px 4px 2px var(--header-bg-color);
    }
    .light {
        color: $font-color-header-light;
        & nav {
            & > div {
                a {
                    color: $font-color-header-light;
                }

                button {
                    color: $font-color-header-light;
                }

                span {
                    background: $font-color-header-light;
                }
            }
        }
        .burger {
            .burger-line {
                background-color: $font-color-heading-text;
            }
        }
    }

    figure {
        margin: auto;
        width: 130px;
        @include respond-to(738) {
            margin: 30px;
        }
    }
}
.logo {
    margin: auto;
    width: 130px;
    height: 20px;
    margin: 20px auto 20px auto;
    cursor: pointer;

    @include respond-to(738) {
        margin: 20px;
    }
    img {
        display: block;
        height: 100%;
        width: 100%;
    }
}
.burger {
    margin: auto 30px;
    display: flex;
    flex-direction: column;
    width: 30px;
    cursor: pointer;

    &-line {
        background-color: $font-color-heading-text;
    }

    &--slide-in {
        margin-left: 30px;
        margin-top: 30px;
        margin-bottom: 70px;

        & div {
            background-color: $slide-in-text-color;
        }
    }
    &-line {
        height: 1px;
        background-color: $bg-color-active-tab;

        &--second {
            width: 50%;
        }
    }
    &--light {
        .burger-line {
            background-color: $font-color-heading-text;
        }
    }
}
