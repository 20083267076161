.report-style-class {
    height: 0px;
    visibility: hidden;
    &--show {
        visibility: visible;
        height: 100vh;
    }
    &--margin {
        margin-top: -190px;
    }
}
iframe {
    border: none;
}

.data-analytic 
{
    &__arcgis {
        .embed-container {
            position: relative;
            height: calc(100vh - 110px);
            max-width: 100%;
    
            iframe, object {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            } 
        } 
    
        small {
              position: absolute;
               z-index: 40;
               bottom: 0;
               margin-bottom: -15px;
         }
    } 
}

