.ant-tabs-top {
    .ant-tabs-nav {
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            display: block;
            height: 1px;
            width: 100%;
            background-color: $main-bg-color;
            opacity: 0.3;
        }
        &:before {
            display: none;
        }

        .ant-tabs-ink-bar {
            border-radius: 100px;
            height: 5px;
        }
    }
}

.ant-tabs-tab {
    padding: 35px 0;
    align-items: flex-end;
    .ant-tabs-tab-btn {
        @extend %font-medium;
        color: $tab-text;
        font-family: $font-family-primary;
        font-size: 16px;
        text-align: center;

        img {
            max-height: 50px;
            max-width: 40px;
        }
    }
    &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            color: $tab-active-text;
        }
    }
}

.ant-tabs-ink-bar {
    background-color: $tab-active-underline;
}
