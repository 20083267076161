.gallery-template {
    &__slider {
        display: block;
        @include respond-to(768) {
            display: none;
        }

        & > div {
            width: 100% !important;
            & > div {
                width: 100% !important;
                position: relative !important;
                & > div {
                    width: 100% !important;
                    padding: 0 30px !important;
                }

                button {
                    height: 60px !important;
                    width: 60px !important;
                    background-color: $main-bg-color !important;
                    border-radius: 50%;

                    & > img {
                        height: 25px;
                    }
                }
            }
        }

        .rsis-container .rsis-image {
            position: relative !important;
        }
    }

    &__main {
        display: none;

        @include respond-to(768) {
            display: block;
        }
    }

    @media screen and (max-width: 768px) {
        h1,h2,h4 {
            text-align: center;
        }
   
    }
}
