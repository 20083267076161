.overview {
    padding-top: 80px;
    padding-left: 50px;

    &__informations {
        padding: 20px 0 40px 0;

        &-main {
            max-height: 240px;
            max-width: 535px;
        }
        &-top {
            max-width: 535px;
        }
    }

    &__span {
        @extend %font-bold;
        font-size: 17px;
        color: $font-color-primary;
    }

    &__edit {
        @extend %font-medium;
        border: 0;
        outline: none;
        font-size: 14px;
        color: $font-color-button-secondary;
        text-decoration: underline 2px solid $font-color-button-secondary;
        cursor: pointer;
        background: transparent;
    }

    &__informations-main {
        margin-top: 40px;
        flex-wrap: wrap;
        flex-direction: column;
        column-gap: 180px;
        row-gap: 20px;

        &--no-gap {
            column-gap: 0;
        }

        &--mt-0 {
            margin-top: 0;
        }
    }

    &__informations-title {
        @extend %font-medium;
        font-family: $font-family-secondary;
        font-size: 12px;
        color: $font-color-primary;

        &--subtitle {
            @extend %font-regular;
            color: $font-color-label;
            font-size: 12px;
        }
    }

    &__main {
        margin-top: 40px;
        width: 90%;

        &--border {
            padding: 20px 40px 120px 5px;
            border-top: 1px solid $bg-color-radio-selected;
        }
    }

    &__title-container {
        margin-bottom: 10px;
    }

    &__title {
        font-size: 24px;
    }

    &__remove-btn {
        height: 30px;
        width: 75px;
    }

    &__user-btn {
        display: block;
        height: 30px;
        width: 100px;
        margin: 30px 0 0;
    }
}
