.invite-modal label {
    font-family: $font-family-1;
    color: #8f93ab;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-transform: none;
    margin-bottom: 20px;
}

.invite-seller .ant-modal-content {
    display: flex;
    width: 676px;
    padding: 15px 25px;
}

.invite-seller .ant-modal-body {
    width: 100%;
}

.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}

.checkbox > input {
    height: 25px;
    width: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #fd615a;
    outline: none;
    transition-duration: 0.3s;
    background-color: white;
    cursor: pointer;
}

.checkbox > input:checked {
    border: 1px solid #fd615a;
    background-color: white;
}

.checkbox > input:checked + span::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #fd615a;
    position: absolute;
    left: 3px;
    top: 1px;
    font-size: 25px;
}

.checkbox > input:active {
    border: 2px solid red;
}

.gallery-modal .ant-modal-content {
    display: flex;
    height: 720px;
    width: 700px;
    padding: 15px 25px;
}
.MuiDataGrid-root .MuiDataGrid-columnHeader--moving {
    background-color: transparent !important;
}
.gallery-modal {
    &__progress-bar {
        top: 6px;
        @extend %relative;
        height: 2px;
        background-color: $border-color-radio-selected;
        z-index: 1;

        &-container {
            width: 100%;
            @extend %absolute;
        }
        &--start {
            width: 1%;
        }

        &--half {
            width: 50%;
        }
        &--full {
            width: 100%;
        }
    }

    &__progress-dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: $border-color-radio-selected;
        z-index: 2;

        &--green {
            background-color: $bg-calendar-day;
        }
    }

    &__main {
        @extend %relative;
        height: 500px;
        width: 500px;
        & > div {
            .cropper-container {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}
