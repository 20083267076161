.transpose-table__wrap  {
    td, th {
        border-radius: 4px;
        font-size: 14px;
        text-align: left;
        padding: 20px;
        width: 350px;
    }
  
}
table {
        border-radius: 4px;
        background-color: white;
        box-shadow: 0 0 20px 0 rgba(182,218,213,0.2);

        border-collapse: collapse;
    }
    tr {
        border-bottom: 1px solid $border-color-card;
    }

    .table-index {
        max-width: 80px;
    }