.property {
    @at-root &-hero {
        @extend %relative;
    }

    @at-root &-info {
        color: $font-color-light;
        padding: 0 30px 60px 60px;
        background: linear-gradient(to top right, rgba(0, 0, 0, 0.6) 20%, rgba(0, 0, 0, 0.1) 50%);
        width: 100%;

        &--flex {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        @at-root &__wrap {
            @extend %absolute0000;
        }
        @at-root &__list {
            border-left: 2px solid white;
            margin-top: 40px;
            max-width: 310px;

            &--no-max {
                max-width: none;
            }

            p {
                @extend %font-light;
                font-size: 10px;
            }

            &--no-max-w {
                max-width: none;
            }
        }

        @at-root &__name {
            @extend %font-light;
            font-size: 35px;
            &--mt {
                margin-top: auto;
            }
        }

        @at-root &__item {
            margin-bottom: 10px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }

        @at-root &__buttons {
            margin-top: 40px;

            a {
                margin-right: 30px;
                padding: 10px;
            }
        }

        @at-root &__value {
            border-left: 1px solid white;
        }

        @at-root &__address {
            @extend %font-light;
            font-family: $font-family-secondary;
            font-size: 10px;
        }
    }

    @at-root &-tabs {
        margin-top: 10px;
    }

    @at-root &-tab {
        @at-root &__container {
            margin-top: 20px;
            padding: 0;

            @include respond-to(768) {
                padding: 0 60px;
            }
        }
        @at-root &__btn-container {
            padding: 0 60px;
        }
        @at-root &__summary {
            @at-root &-add-template {
                flex-direction: column;
                height: 440px;
                margin-top: 70px;

                p {
                    @extend %font-bold;
                    color: $font-color-primary;
                    font-size: 18px;
                    margin-top: 20px;
                }

                .par-m-none {
                    margin-top: 0;
                }

                &--height {
                    height: auto;
                }
            }
            @at-root &-have-template {
                flex-direction: column;
                height: 120px;
                margin-top: 70px;

                p {
                    @extend %font-bold;
                    color: $font-color-primary;
                    font-size: 18px;
                    margin-top: 20px;
                }
            }
        }
    }

    &__dropdown {
        padding: 0 20px;

        @include respond-to(768) {
            padding: 0;
        }
    }

    &__dropdown {
        @extend %relative;
    }

    &__dropdown-main {
        @extend %absolute;
        width: 95%;
        top: 100%;
        left: 5%;
        z-index: 999;
        max-height: 260px;
        overflow-y: scroll;
        background-color: $bg-color-button-secondary;
    }
    &__dropdown-single {
        padding: 3px;
        font-size: 16px;

        &:hover {
            background-color: $border-color-footer;
            cursor: pointer;
        }
    }

    &__tree-dots {
        position: absolute;
        top: -5px;
        right: 55px;
    }
}

.property__dropdown-list {
    & > div {
        background-color: $bg-color-button-secondary !important;

        &:hover {
            background-color: $font-color-header-light !important;
        }
    }
}

.property-tabs {
    .ant-tabs {
        .ant-tabs-nav {
            display: none;

            @include respond-to(768) {
                display: flex;
            }
        }
    }
}
//Property Summary
.property-summary {
    @at-root &-multiple-text-image {
        @at-root &__wrap {
            padding-top: 50px;
        }

        @at-root &__list {
            margin-bottom: 40px;
            row-gap: 20px;
            flex-wrap: wrap;
            padding: 0 20px 20px 20px;
        }

        @at-root &__single {
            background-color: white;
            padding: 0;

            @include respond-to(768) {
                padding-bottom: 15px;
            }

            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                padding: 0 30px;
            }
            h3 {
                font-size: 32px;
            }

            h4 {
                font-size: 24px;
            }
        }
    }

    @at-root &-table {
        @at-root &__wrap {
            padding-top: 50px;
        }
    }
}

// Research
.research {
    @at-root &-multiple-text-image {
        @at-root &__wrap {
            padding-top: 50px;
        }

        @at-root &__list {
            padding: 0 0 60px;
        }

        @at-root &__single {
            border: 2px solid $border-color-card;
            border-radius: 5px;
            margin: 35px;
            padding: 15px 0;
            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                padding: 0 30px;
            }
            h3 {
                font-size: 32px;
            }

            h4 {
                font-size: 24px;
            }
        }
    }

    @at-root &-table {
        @at-root &__wrap {
            padding-top: 50px;
        }
    }
}

.image-summary {
    &-container {
        overflow: hidden;
        display: flex;
        justify-content: center;
    }
    width: auto;
    min-height: 500px;

    @include respond-to(768) {
        max-height: 400px;
    }
    @include respond-to(1400) {
        width: 100%;
    }
}

.ant-switch-checked {
    background-color: $bg-color-active-tab;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-tab {
    padding: 10px 0;
}
.faq .ant-tabs > .ant-tabs-nav .ant-tabs-tab {
    padding: 10px 5px 10px 0;
}

.property-tabs__styled > .ant-tabs > .ant-tabs-nav {
    margin: 0 60px 20px 60px;
}

input[type='radio'] {
    height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
}

input[type='radio']:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

input[type='radio']:checked:before {
    background-color: $font-color-light;
    border: 4px solid $bg-color-button-primary;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
    justify-content: space-between;
}

.property-tabs-mobile {
    .ant-popover-inner-content {
        padding: 0;
    }

    .button-main {
        border: none;
    }
}

.property-tab-mobile-menu {
    position: fixed;
    bottom: 5%;
    right: 5%;
    z-index: 100;
    width: 50px;
    height: 50px;

    .menu-icon {
        width: 20px;
        font-size: 1.5em;
        height: 40px;
    }
}