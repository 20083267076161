.bid-info-page {
    padding-top: 80px;
    padding-left: 50px;

    &__form {
        padding-bottom: 120px;
    }

    &__form-cont {
        position: relative;
    }

    &__error-mess {
        position: absolute;
        bottom: -20px;
        left: 20px;
    }

    &__error-mess {
        color: $font-color-error;
    }
}

.mb-xxl-20 {
    @include respond-to(1680) {
        margin-bottom: 20px;
    }
}
