.listing-type {
    padding-top: 80px;
    padding-left: 50px;

    &__help {
        @extend %absolute;
        @extend %font-light;
        color: $font-color-label;
        font-family: $font-family-1;
        font-size: 14px;
        line-height: 18px;
        right: 30px;

        &-link {
            @extend %font-medium;
            font-family: $font-family-1;
            color: $font-color-button-secondary;
            margin-left: 5px;
            font-size: 14px;
            line-height: 18px;
            text-decoration: underline;
        }
    }

    &__form {
        &-label {
            color: $font-color-primary;
            font-family: $font-family-1;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-transform: none;

            &--small {
                font-size: 14px;
            }
            &--small-fw {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }

    &__form p {
        width: 354px;
        line-height: 24px;
        font-size: 12px;
    }

    &__checkbox-container {
        @extend %absolute;
        width: 90%;
        bottom: 150px;
        padding-top: 20px;
        border-top: 1px solid $border-color-footer;
    }

    & .button-wrap {
        width: 95%;
    }
}

.button-container {
    width: 95%;
}
