.collapsedArrowIcon {
    cursor: pointer;
    transform: rotate(0deg);
    transition: all 0.25s ease-in-out;
    margin-left: 10px;
}

.expandedArrowIcon {
    cursor: pointer;
    transform: rotate(90deg);
    transition: all 0.25s ease-in-out;
    margin-left: 10px;
}