.signNDAModal {
    display: flex;
    &__subtitle {
        @extend %font-medium;
        font-family: $font-family-secondary;
        color: $font-color-primary;

        &--center {
            @extend %font-light;
            font-family: $font-family-primary;
            text-align: center;
        }

        &--left {
            @extend %font-light;
            font-family: $font-family-primary;
            text-align: left;
        }
    }

    &__required {
        text-align: center;
        font-size: 26px !important;
        font-family: $font-family-secondary !important;
        color: $font-color-button-secondary !important;
    }

    &__frame {
        width: 100%;
        height: 800px;
    }

    &__fields {
        display: flex;
        flex-direction: row;
        justify-content: center;

        label:not(:first-child) {
            margin-right: 0px;
        }
    }

    &__form {
        &__buttons {
            display: flex;

            button {
                margin: auto;
            }
        }
    }
}

.nda-options-modal {
    .ant-modal-content {
        // min-height: 500px;
        // max-height: 550px;
        width: 676px;
        padding: 15px 25px;
    }
}
