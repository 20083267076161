.root {
    width: 100%;
    font-family: 'Rubik', sans-serif;
}

.floors {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    line-height: 20px;
}

.floor {
    & + .floor {
        margin-top: 2px;
    }
}
