.ant-table-cell{
    @extend %font-medium;
    font-family: $font-color-primary;
    font-style: 12px !important;
}

.ant-table-row{
    font-weight: 400 !important;
    font-family: $font-color-primary !important;
    font-size: 14px !important;
    color: $font-color-table-row !important;
}