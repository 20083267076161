.ant-picker {
    background-color: $bg-color-button-secondary;
    border: 1px solid $border-color-input;
    height: 40px;
    width: 100%;

    .ant-picker-input > input {
        &::placeholder {
            color: $font-color-primary;
            font-family: $font-family-secondary;
            font-size: 10px; /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $font-color-primary;
            font-family: $font-family-secondary;
            font-size: 10px;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $font-color-primary;
            font-family: $font-family-secondary;
            font-size: 10px;
        }

        &:hover {
            border: none;
        }
    }
}
.ant-select {
    border: 1px solid transparent;

    &:hover {
        border: 1px solid #40a9ff;
        border-radius: 3px;
    }
}
