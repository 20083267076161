.dashboard-body section.module-intro.transaction-data {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
}

.dashboard-body section.module-intro.transaction-data .content-text {
    width: 100%;
}

.dashboard-body section.module-intro.transaction-data .content-text .list li p {
    min-width: 40%;
}

.dashboard-body section.module-intro.transaction-data .content-text .list li span {
    width: auto;
    min-width: 230px;
}

.dashboard-body section.module-intro.transaction-data .content-text .list li span span {
    padding-left: 0;
}