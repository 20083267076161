.grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 30px;
    padding: 10px;
}
.grid-item {
    padding: 10px;
    display: inline-grid;
}

.grid-button {
    align-self: end;
}
