.d-providers {
    width: 100%;

    & span {
        color: $font-color-primary;
        font-family: $font-family-1;
        font-size: 42px;
        font-weight: 300;
        line-height: 62px;
        margin-left: auto;
        margin-right: 30px;
    }

    &__text {
        @extend %font-light;
        color: $font-color-primary;
        font-family: $font-family-1;
        width: 969px;
        font-size: 22px;
        letter-spacing: 0.82px;
    }

    & img {
        height: 45px;
        width: 45px;
    }

    &__info {
        width: 95%;

        &-container {
            height: 236px;
        }

        &-left,
        &-right {
            width: 50%;
            border-left: 5px solid $font-color-secondary;
            border-radius: 3px;
            padding-left: 45px;
            height: 132px;
        }

        &-text {
            padding-left: 42px;
            width: 251px;
        }

        &-title {
            @extend %font-medium;
            color: $font-color-primary;
            font-family: $font-family-1;
            font-size: 24px;
            line-height: 34px;
        }

        &-body {
            @extend %font-medium;
            color: $font-color-label;
            font-family: $font-family-1;
            font-size: 16px;
            line-height: 22px;
        }
    }

    &__logos img {
        height: 73px;
        width: 117px;
        object-fit: contain;
        margin-top: 53px;
        margin-bottom: 48px;
    }
}

.dots-wrapper {
    padding-left: 34px;
}

.dots-container {
    margin-top: 50px;
    margin-bottom: 27px;
    border-top: 3px solid $border-color-input;
    width: 1100px;
}

.dots-dot {
    margin-top: -20px;
}

.dot {
    @extend %relative;
    border: 1px solid $border-color-button-primary;
    width: 39px;
    height: 39px;
    border-radius: 50%;
    background-color: $bg-color-dot;
}

.dot span {
    color: $font-color-button-secondary;
    @extend %absolute;
    font-size: 130px;
    top: -23px;
    left: 4px;
}

.dots-text {
    width: 90%;
    margin-bottom: 88px;
    padding-left: 34px;

    &__text {
        width: 205px;

        &-title {
            color: $font-color-primary;
            font-family: $font-family-1;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        &-body {
            font-family: $font-family-2;
            color: $font-color-label;
            font-size: 14px;
            line-height: 19px;
        }
    }
}
