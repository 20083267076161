.statistic {
    flex-direction: column;
    gap: 20px;
    @include respond-to(640) {
        flex-direction: row;
    }
    &__clock {
        height: 75px;
        width: 75px;
        background-color: $border-color-button-primary-light;
        border-radius: 50%;
    }

    &__time {
        @extend %font-light;
        color: $font-color-primary;
        font-family: $font-family-2;
        font-size: 30px;
    }

    &__info {
        color: $font-statistic-info;
        font-family: $font-family-2;
        font-size: 21px;

        &--cometitivness {
            @extend %absolute;
            text-align: center;
            top: 100px;
            left: 30px;
        }

        &--price {
            font-size: 15px;
        }
    }

    &__clock-font {
        font-size: 27px;
        color: $bg-color-button-primary;
    }

    &__countdown {
        padding: 30px;
        border: 1px solid $border-color-input;
        border-radius: 9.94px;
        background-color: $font-color-button-primary;
        box-shadow: 0 0 28px 0 $bg-underline-op;

        &--time-up {
            min-width: 350px;
            min-height: 230px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 28px 0 $bg-underline-op;
            border-radius: 9.94px;
        }
    }

    &__perc {
        @extend %relative;
        padding: 30px 50px;
        border: 1px solid $border-color-input;
        border-radius: 9.94px;
        background-color: $font-color-button-primary;
        box-shadow: 0 0 28px 0 $bg-underline-op;
    }

    &__subtitle {
        @extend %font-medium;
        color: $border-color-dashed;
        font-size: 24px;
    }
}
