.collapsedArrowIcon {
    transform: rotate(0deg);
    transition: all 0.5s ease-in-out;
}

.expandedArrowIcon {
    transform: rotate(180deg);
    transition: all 0.5s ease-in-out;
}

.expandedInfo {
    padding-left: 40px;

    .expandedTitle {
        min-width: 100px
    }
}