.bid-overview-page {
    padding-top: 80px;
    padding-left: 50px;

    &__bid {
        padding-bottom: 100px;
    }

    &__hedding {
        padding: 0 14px;
    }

    &__company-main {
        border: 1px solid $border-color-card;
        padding-top: 20px;
        box-shadow: 0 0 20px 0 rgba(182, 218, 213, 0.3);
        border-radius: 7px;
    }

    &__company-secondary {
        padding-top: 20px;
        border: 1px solid $border-color-card;
        box-shadow: 0 0 20px 0 rgba(182, 218, 213, 0.3);
        border-radius: 7px;
    }

    &__bid {
        border: 1px solid $border-color-card;
        padding-top: 20px;
        box-shadow: 0 0 20px 0 rgba(182, 218, 213, 0.3);
        border-radius: 7px;
    }
}
