.property {
    @at-root &__radio {
        @extend %relative;
        display: block;
        font-size: 15px;
        letter-spacing: 0;
        cursor: pointer;

        input {
            @extend %absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        input:checked ~ .property__radio-option {
            .property__radio-select {
                border-color: $border-color-radio-selected;
                background-color: $bg-color-radio-selected;
                &:after {
                    border-right-color: $border-color-radio-selected;
                    border-bottom-color: $border-color-radio-selected;
                }
            }
        }

        @at-root &-option {
            @extend %absolute;
            padding: 20px;

            h5 {
                color: $font-color-primary;
                text-transform: capitalize;
            }
            p {
                @extend %font-regular;
                font-size: 15px;
                text-transform: initial;
            }
        }

        @at-root &-select {
            @extend %relative;
            border: 1px solid $border-color-secondary;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            &:after {
                @extend %absolute;
                content: '';
                border-right: 1px solid $border-color-secondary;
                border-bottom: 1px solid $border-color-secondary;
                width: 5px;
                height: 9px;
                transform: rotate(45deg);
                top: 3px;
                left: 7px;
            }
        }
    }
    @at-root &-type__radio {
        border: 2px solid $border-color-card;
        border-radius: 10px;
        height: 175px;
        width: 233px;
        margin: 40px 50px 50px 0;

        figure {
            background-color: $border-color-card;
            border-radius: 50%;
            padding: 11px;
        }
    }

    &__radio {
        &--bigger {
            height: 205px;
        }

        &--small {
            height: 170px;
            margin: 0;
        }
    }

    &-info {
        margin-top: auto;
    }

    &-info__deal-timeline {
        margin-top: auto;
        margin-bottom: 100px;
        background-color: $main-bg-color;
        padding: 15px;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &-card {
            padding: 0 10px 10px 10px;
            background-color: $bg-color-button-secondary;
            width: 60%;
            align-self: center;
        }

        &-text {
            color: $main-text-color;
            font-size: 18px;
        }
    }

    &-info__share-container {
        position: absolute;
        top: 20px;
        right: 25px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &-info__deal-line-modal {
        width: 1000px !important;
    }

    &-info__share {
        &-btn {
            .ant-switch-handle {
                height: 35px;
                width: 38px;
                &::before {
                    border-radius: 50%;
                }
            }
        }

        .ant-switch-checked {
            background-color: $main-bg-color;
            .ant-switch-handle {
                left: calc(100% - 38px - 2px);
            }
        }

        :hover {
            background-color: $main-bg-color;
            color: $main-text-color;
            .ant-switch-handle {
                background-color: transparent;
            }
        }

        &--scale {
            transform: scale(0.7);
            display: flex;
            justify-content: center;
        }
    }

    &-info__share-txt {
        color: $main-bg-color;
    }
}
.create-edit-property-form {
    .form-group {
        min-height: 90px;
        justify-content: flex-end;
        margin-bottom: 25px;
        padding-left: 30px;
    }

    .error-text {
        @extend %absolute;
        bottom: 0;
    }

    .hidden {
        display: none;
    }
    .ant-select,
    .ant-select-selection-placeholder {
        font-size: 10px;
        color: $font-color-input;
    }
}

.add-property-image-builder {
    @at-root &__image {
        @extend %relative;
    }

    &__check-icon {
        color: $bg-underline;
        font-size: 20px;
    }
    &__times-icon {
        color: $bg-overview-card-logo;
        font-size: 20px;
    }

    &__def-image {
        display: block;
        max-height: 2400px;
        max-width: 1500px;
        margin: 0 auto;
    }

    @at-root &__upload {
        @extend %flex-absolute-centered-max-size;

        .ant-btn {
            height: 53px;
            width: 300px;
            border: 1px dashed $border-color-dashed;
            border-radius: 8px;

            p {
                text-decoration: underline;
            }
        }

        &--template {
            display: block;
            left: 15px;
        }

        &--sm {
            .ant-btn {
                width: 100%;
            }
            .ant-upload {
                width: 100%;
            }
        }

        &--general {
            .ant-btn {
                height: 350px;
                width: 100%;
            }
        }
    }

    @at-root &__portfolio {
        @extend %flex-absolute-centered-max-size;

        .ant-btn {
            margin-top: 100px;
            height: 53px;
            width: 420px;
            border: 1px dashed $border-color-dashed;
            border-radius: 8px;

            p {
                text-decoration: underline;
            }
        }
    }

    &__textarea {
        border-radius: '4px';
        height: '150px';
        font-size: '14px';
    }
}

.button-wrap {
    box-shadow: 4px 0 10px 0 rgba(28, 64, 80, 0.1);
    height: 99px;
    button {
        margin-left: 30px;
    }
}

.ant-upload.ant-upload-select-picture-card {
    width: inherit;
    height: inherit;
    border: none;
    margin-top: 10px;
}

.ant-upload-list-picture-card-container {
    margin-top: 10px;
}

.previous-page {
    margin-right: auto;
    color: $font-color-button-secondary;
    font-family: $font-family-1;
    @extend %font-medium;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
}

.create-edit-property-spinner {
    @extend %absolute;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    color: $font-color-button-secondary;
}

.spinner-red {
    color: $font-color-button-secondary;
}
