.dashboard-body section {
    padding: 0 25px;
}

  @media screen and (min-width: 1024px) {
    .dashboard-body section {
      padding: 0 45px;
    }

    img.showcase {
      margin-top: 160px;
    }
  }
  .dashboard-body section .content-text .button-primary {
    background-color: #fd615a;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #fd615a;
    border-radius: 4px;
    height: 50px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  @media screen and (min-width: 1024px) {
    .dashboard-body section .content-text .button-primary {
      max-width: 325px;
    }
  }
  .dashboard-body section .content-text .heading {
    color: #1c4050;
    font-size: 32px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    letter-spacing: 0.8px;
    position: relative;
  }
  .dashboard-body section .content-text .heading::after {
    content: "";
    background-color: #b6dad5;
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 0;
    position: absolute;
  }
  .dashboard-body section .content-text .block-article h3 {
    color: #6E7189;
    font-family: $font-family-2;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 48px;
    margin-bottom: 25px;
  }
  .dashboard-body section .content-text .block-article p {
    color: #6E7189;
       font-family: $font-family-2;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 28px;
    margin-bottom: 25px;
    max-width: 763px;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section .content-text .block-article p {
      margin-bottom: 0;
    }
  }
  .dashboard-body section.module-intro {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-intro {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
    }
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-intro .bg {
      width: 50%;
      margin-right: -45px;
    }
  }
  .dashboard-body section.module-intro .content-text {
    padding-top: 25px;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-intro .content-text {
      width: 50%;
    }
  }
  .dashboard-body section.module-intro .content-text .button-main {
    margin-bottom: 25px;
  }
  .dashboard-body section.module-intro .content-text .list {
    margin-top: 25px;
    margin-bottom: 40px;
    position: relative;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-intro .content-text .list {
      padding-left: 50px;
    }
  }
  .dashboard-body section.module-intro .content-text .list::before {
    content: "";
    display: none;
    position: absolute;
    background-color: #1C4050;
    width: 1px;
    height: 100%;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-intro .content-text .list::before {
      left: 17px;
      display: block;
    }
  }
  .dashboard-body section.module-intro .content-text .list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-intro .content-text .list li {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      margin-bottom: 5px;
    }
  }
  .dashboard-body section.module-intro .content-text .list li p {
    min-width: 220px;
    color: #1C4050;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 5px;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-intro .content-text .list li p {
      margin-bottom: 0px;
    }
  }
  .dashboard-body section.module-intro .content-text .list li span {
    color: #1C4050;
    font-family: $font-family-2;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    padding-left: 25px;
    display: block;
    position: relative;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-intro .content-text .list li span {
      width: 230px;
    }
  }
  .dashboard-body section.module-intro .content-text .list li > span::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 1px;
    height: 24px;
    background-color: #1C4050;
  }
  .dashboard-body section.module-intro .content-text .block-row-circle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-intro .content-text .block-row-circle {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
    }
  }
  .dashboard-body section.module-intro .content-text .block-row-circle .blk-circle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 32px;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-intro .content-text .block-row-circle .blk-circle {
      margin-right: 32px;
    }
  }
  .dashboard-body section.module-intro .content-text .block-row-circle .blk-circle:last-child {
    margin-right: 0;
  }
  .dashboard-body section.module-intro .content-text .block-row-circle .blk-circle.big .circle {
    height: 102px;
    width: 102px;
    background-color: #1C4050;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-intro .content-text .block-row-circle .blk-circle.big {
      margin-right: 70px;
    }
  }
  .dashboard-body section.module-intro .content-text .block-row-circle .blk-circle .circle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 99px;
    background-color: #B6DAD5;
    height: 68px;
    width: 68px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23.91px;
    margin-bottom: 15px;
  }
  .dashboard-body section.module-leasing {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-leasing {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
  .dashboard-body section.module-leasing .content-text {
    margin-bottom: 40px;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-leasing .content-text {
      margin-bottom: 80px;
    }
  }
  .dashboard-body section.module-leasing .block-data-table {
    margin: 0 -25px;
  }
  .dashboard-body section.module-capital-market .content-text {
    padding: 40px 25px;
    margin: 0 -25px;
    background-color: #F7FDFC;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-capital-market .content-text {
      padding: 80px 45px;
      margin: 0 -45px;
    }
  }
  .dashboard-body section.module-asset-data {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .dashboard-body section.module-asset-data .block-article h3 {
    color: #6E7189;
    font-family: $font-family-2;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 48px;
    margin-bottom: 25px;
  }
  .dashboard-body section.module-asset-data .block-article p {
    color: #6E7189;
  font-family: $font-family-2;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 28px;
    margin-bottom: 25px;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-asset-data .block-article p {
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-asset-data {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
    }
    .dashboard-body section.module-asset-data .content-text {
      width: 60%;
      padding-right: 50px;
    }
    .dashboard-body section.module-asset-data .bg {
      width: 40%;
    }
  }
  .dashboard-body section.bg-light-green {
    background-color: #F7FDFC;
  }
  .dashboard-body section.bg-dark-green {
    background-color: #B6DAD5;
  }
  .dashboard-body section.module-sub-market {
    padding-top: 50px;
    padding-bottom: 25px;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-sub-market {
      padding-top: 100px;
      padding-bottom: 50px;
    }
  }
  .dashboard-body section.module-model-estimate {
    padding-top: 25px;
    padding-bottom: 50px;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-model-estimate {
      padding-top: 40px;
      padding-bottom: 100px;
    }
  }
  .dashboard-body section.module-trade-score {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-trade-score {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  .dashboard-body section.module-trade-score .list-block-item {
    margin-top: 27.5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .dashboard-body section.module-trade-score .list-block-item .item {
    width: 100%;
    margin-bottom: 25px;
  }
  @media screen and (min-width: 768px) {
    .dashboard-body section.module-trade-score .list-block-item .item {
      width: calc(50% - 25px);
    }
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-trade-score .list-block-item .item {
      width: calc(20% - 25px);
    }
  }
  .dashboard-body section.module-trade-score .list-block-item .item .block-items {
    min-height: 170px;
    border: 0.84px solid #EFF3F9;
    border-radius: 5.89px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 0 17px 0 rgba(182, 218, 213, 0.3);
            box-shadow: 0 0 17px 0 rgba(182, 218, 213, 0.3);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 15px;
  }
  .dashboard-body section.module-trade-score .list-block-item .item h4 {
    color: #1C4050;
    font-family: $font-family-primary;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.36px;
    line-height: 32px;
    margin-bottom: 15px;
    text-align: center;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-trade-score .list-block-item .item h4 {
      font-size: 28.62px;
      line-height: 37px;
    }
  }
  .dashboard-body section.module-trade-score .list-block-item .item p {
    color: #8798AD;
    font-family: $font-family-primary;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-trade-score .list-block-item .item p {
      font-size: 12.63px;
      line-height: 18.52px;
    }
  }
  .dashboard-body section.module-iframe-map {
    padding: 50px 25px;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-iframe-map {
      padding: 100px;
    }
  }
  .dashboard-body section.module-iframe-map .block-contain-map {
    border-radius: 8px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 0 20px 0 rgba(215, 222, 227, 0.39);
            box-shadow: 0 0 20px 0 rgba(215, 222, 227, 0.39);
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-iframe-map .block-contain-map {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }
  .dashboard-body section.module-iframe-map .block-contain-map .block-menu-checkbox {
    width: 100%;
    padding: 25px;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-iframe-map .block-contain-map .block-menu-checkbox {
      padding: 45px;
      width: 30%;
    }
  }
  .dashboard-body section.module-iframe-map .block-contain-map .block-menu-checkbox h3 {
    color: #6E7189;
    font-family: $font-family-primary;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-iframe-map .block-contain-map .block-menu-checkbox h3 {
      margin-bottom: 60px;
    }
  }
  .dashboard-body section.module-iframe-map .block-contain-map .block-menu-checkbox label {
    color: #6E7189;
    font-family: $font-family-2;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 12px;
    text-transform: none;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-iframe-map .block-contain-map .block-menu-checkbox label {
      margin-bottom: 20px;
    }
  }
  .dashboard-body section.module-iframe-map .block-contain-map .block-menu-checkbox .list-maps {
    position: relative;
  }

  .dashboard-body section.module-iframe-map .block-contain-map .block-menu-checkbox .list-maps input {
    position: absolute;
    left: -99999px;
    opacity: 0;
  }
  .dashboard-body section.module-iframe-map .block-contain-map .block-menu-checkbox .list-maps label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
  }
  .dashboard-body section.module-iframe-map .block-contain-map .block-menu-checkbox .list-maps input ~ label::before {
    content: "";
    display: block;
    height: 16px;
    width: 16px;
    min-width: 16px;
    border: 1px solid #A1ABBD;
    border-radius: 99px;
    margin-right: 10px;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  .dashboard-body section.module-iframe-map .block-contain-map .block-menu-checkbox .list-maps input ~ label::after {
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    min-width: 10px;
    background-color: #fff;
    border-radius: 99px;
    left: 3px;
    position: absolute;
    opacity: 0;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  .dashboard-body section.module-iframe-map .block-contain-map .block-menu-checkbox .list-maps input:checked ~ label::before {
    content: "";
    display: block;
    height: 16px;
    min-width: 16px;
    width: 16px;
    border: 1px solid #FD615A;
    background-color: #FD615A;
  }
  .dashboard-body section.module-iframe-map .block-contain-map .block-menu-checkbox .list-maps input:checked ~ label::after {
    content: "";
    opacity: 1;
  }
  .dashboard-body section.module-iframe-map .block-contain-map .map-container {
    width: 100%;
    padding-top: 0px;
  }
  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-iframe-map .block-contain-map .map-container {
      padding-top: 96px;
      border-left: 1px solid #C6CBD4;
    }
  }
  .dashboard-body section.module-iframe-map .block-contain-map .map-container .map {
    padding: 30px 0;
    border-top: 1.32px solid #F4F4F4;
    border-bottom: 1.32px solid #F4F4F4;
  }
  .dashboard-body section.module-iframe-map .block-contain-map .map-container .map iframe {
    width: 100%;
    height: 343px;
  }

  @media screen and (min-width: 1024px) {
    .dashboard-body section.module-iframe-map .block-contain-map .map-container .map {
      padding: 30px;
    }
  }

  .dashboard-body section.module-iframe-map .block-contain-map .map-container .map-info {
    padding: 30px;
  }

  .dashboard-body section.module-iframe-map .block-contain-map .map-container .map-info .name {
    color: #1C4050;
    font-family: $font-family-primary;
    font-size: 26px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 16px;
  }

  .dashboard-body section.module-iframe-map .block-contain-map .map-container .map-info .desc {
    color: #6E7189;
    font-family: $font-family-2;
    font-size: 12px;
    letter-spacing: 0.15px;
    line-height: 15px;
  }

  .dashboard-body .green-spacing {
    display: block;
    height: 106px;
    width: 100%;
    background-color: #F7FDFC;
  }

  .dashboard-properties {

    .property-card__image img{
      height: 300px;
      max-height: 300px;
    }

    .property-card-dashboard {
      margin-top:0px;
    }

    .property-card__element-value {
      text-align: right;
    }
    .container-search__input {
      height: 48px;
    }

    .checkbox > input {
      height: 18px;
      width: 18px;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      border: 1px solid #fd615a;
      outline: none;
      transition-duration: 0.3s;
      background-color: white;
      cursor: pointer;
  }
  
  .checkbox > input:checked {
      border: 1px solid #fd615a;
      background-color: white;
  }
  
  .checkbox > input:checked + span::before {
      content: '\2713';
      display: block;
      text-align: center;
      color: #fd615a;
      position: absolute;
      left: 4px;
      top: 0px;
      font-size: 14px;
  }
  
  .checkbox > input:active {
      border: 2px solid red;
  }
  
}

  .module-bucket-data {
      .ant-tabs-bottom .ant-tabs-nav {
        padding-left: 30px; 
        border-bottom: none;
      }

      .ant-tabs-tab {
        min-width: 110px;
      }
  }