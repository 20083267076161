.marketYieldChartDiv {
    &__item-color {
        width: 25px;
        height: 10px;

        &--blue {
            background-color: #6771dc;
        }
        &--orange {
            background-color: #dc8c67;
        }
        &--total {
            height: 5px;
            background-color: #c0c0c0;
        }
    }
}
