// HTML Tags

html,
body {
    height: 100%;
}

body {
    color: $font-color-primary;
    font-family: $font-family-primary;
}

h1,
h2,
h3,
h4,
h5,
a {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
}

p {
    font-size: 12px;
    margin-bottom: 0;
}

a,
button {
    font-size: 16px;
    text-decoration: none;
    &:hover,
    &:focus,
    &:visited {
        color: inherit;
        text-decoration: none;
    }
}

label {
    color: $font-color-label;
    font-family: $font-family-secondary;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 1.13px;
    text-transform: uppercase;
}

figcaption {
    margin-left: 30px;
}

ul {
    list-style-type: none;
    padding: 0;
}

img {
    width: 100%;
}

.ant-picker-input {
    input {
        font-size: 10px;
        color: $font-color-input;
    }
}

input {
    border: 1px solid $border-color-input;
    background-color: $bg-color-button-secondary;
    box-sizing: border-box;
    height: 40px;
    font-size: 10px;
    padding-left: 5px;
    color: $font-color-input;

    &:hover {
        border: 1px solid #40a9ff;
        border-radius: 3px;
    }
}

textarea {
    border: 1px solid $border-color-input;
    background-color: $bg-color-button-secondary;
    box-sizing: border-box;
    padding-left: 15px;
    color: $font-color-input;
    font-size: 10px;
}

::placeholder {
    color: $font-color-input;
    font-family: $font-family-secondary;
    font-size: 10px; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $font-color-input;
    font-family: $font-family-secondary;
    font-size: 10px;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $font-color-input;
    font-family: $font-family-secondary;
    font-size: 10px;
}

// Typography
.heading {
    @extend %relative;
    @extend %font-light;
    @extend %heading;
    color: $font-color-heading;
    font-size: 21px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    letter-spacing: 0.8px;
}

.wizard-heading {
    @extend %font-light;
    @extend %heading;
    color: $font-color-heading;
    font-size: 18px;
    letter-spacing: 0.5px;
    padding-bottom: 15px;

    &--loading {
        @extend %loading;
        text-align: center;
        animation-name: fadeInOut;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.main-text {
    color: $font-color-primary;
    font-size: 16px;
    font-weight: 400;
}

.secondary-text {
    @extend %font-medium;
    font-family: $font-family-secondary;
    font-size: 16px;
}

.text-rubik-16 {
    color: $font-color-label;
    font-family: $font-family-2;
    font-size: 16px;
    text-transform: none;
}

.text-airbnb-18 {
    @extend %font-medium;
    color: $font-color-primary;
    font-family: $font-family-1;
    font-size: 18px;
    line-height: 24px;
}

.error-text {
    color: $font-color-error;
}

.position-relative {
    @extend %relative;
}

.error-text-absolute {
    @extend %absolute;
    top: 100%;
    font-size: 8px;
    color: $font-color-error;
}

.color-red {
    color: $font-color-error;
}

.color-green {
    color: $border-color-radio-selected;
}

.color-gray {
    color: $font-color-tabs;
}

// Containers
.main-container {
    @extend %relative;
    max-width: 1200px;
    margin: auto;
    overflow: hidden;
}

.container {
    padding: 60px 0;
}

.container__data {
    max-width: 1400px;
    margin: auto;
}

// Pagination
.pagination {
    width: 100%;
}
.pagination-search {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

// Buttons
.button-main {
    @extend %font-medium;
    border: 1px solid $bg-main-button;
    font-size: 10px;
    height: 40px;
    width: 147px;
    cursor: pointer;
}

.button-small {
    @extend %font-medium;
    border: none;
    border-radius: 4px;
    font-size: 10px;
    height: 30px;
    cursor: pointer;
}

.button-primary {
    background-color: $bg-main-button;
    color: $bg-secondary-button;

    &:hover {
        background-color: $bg-color-button-secondary;
        border-color: $bg-main-button;
        color: $bg-main-button;
    }

    &--green {
        background-color: $border-color-radio-selected;
        color: $bg-secondary-button;

        &:hover {
            background-color: $bg-secondary-button;
            color: $border-color-radio-selected;
        }
    }
}

.button-secondary {
    background-color: $bg-color-button-secondary;
    color: $bg-main-button;

    &:hover {
        color: $bg-color-button-secondary;
        background-color: $bg-main-button;
    }
}

//
.d-none {
    display: none;
}

// Flex
.d-flex {
    display: flex;
}

.flex-1 {
    flex: 1;
}

.f-wrap {
    flex-wrap: wrap;
}

.gap-5 {
    gap: 5px;
}

.gap-10 {
    gap: 10px;
}

.gap-15 {
    gap: 15px;
}

.gap-20 {
    gap: 20px;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-space-around {
    justify-content: space-around;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.flex-direction-column {
    flex-direction: column;
}

.flex-direction-row {
    flex-direction: row;
}

.flex-direction-reverse {
    flex-direction: row-reverse;
}

.flex-direction-column-reverse {
    flex-direction: column-reverse;
}

.justify-content-space-evenly {
    justify-content: space-evenly;
}

// Forms
.form-group {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.main-radio-button {
    @extend %relative;
    input {
        @extend %absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    input:checked ~ .property-type__radio-option {
        .property-type__radio-select {
            border-color: $border-color-radio-selected;
            background-color: $bg-color-radio-selected;
            &:after {
                border-right-color: $border-color-radio-selected;
                border-bottom-color: $border-color-radio-selected;
            }
        }
    }

    @at-root &__select {
        @extend %relative;
        border: 1px solid $border-color-secondary;
        // border-radius: 50%;
        height: 20px;
        width: 20px;
        &:after {
            @extend %absolute;
            content: '';
            border-right: 1px solid $border-color-secondary;
            border-bottom: 1px solid $border-color-secondary;
            width: 5px;
            height: 9px;
            transform: rotate(45deg);
            top: 3px;
            left: 7px;
        }
    }
}

// Margins
.m-auto {
    margin: auto;
}
.mr-auto {
    margin-right: auto;
}
.ml-auto {
    margin-left: auto;
}
.mt-auto {
    margin-top: auto;
}
.m-zero {
    margin: 0;
}

.my-50 {
    margin: 50px 0;
}

.my-100 {
    margin: 100px 0;
}

.my-20 {
    margin: 20px 0;
}
.mxl-40 {
    margin-left: 40px;
}

.mxl-30 {
    margin-left: 30px;
}

.mxl-20 {
    margin-left: 20px;
}

.mxl-15 {
    margin-left: 15px;
}

.mxl-10 {
    margin-left: 10px;
}

.mb-0 {
    margin-bottom: 0;
}

.ml-5 {
    margin-left: 5px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-10 {
    margin-left: 10px;
}
.ml-20 {
    margin-left: 20px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-40 {
    margin-left: 40px;
}
.ml-50 {
    margin-left: 50px;
}
.ml-60 {
    margin-left: 60px;
}
.ml-70 {
    margin-left: 70px;
}
.ml-80 {
    margin-left: 80px;
}

.ml-5 {
    margin-left: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-30 {
    margin-bottom: 30px;
}
.mb-50 {
    margin-bottom: 50px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mb-120 {
    margin-bottom: 120px;
}

.mt-200 {
    margin-top: 200px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-30 {
    margin-top: 30px;
}
.mt-35 {
    margin-top: 35px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-10 {
    margin-top: 10px;
}
.mt-120 {
    margin-top: 120px;
}
.mt-5 {
    margin-top: 5px;
}
.mt-3 {
    margin-top: 3px;
}

.mt-0 {
    margin-top: 0px;
}

.mr-30 {
    margin-right: 30px;
}

.mt-20 {
    margin-top: 20px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-25 {
    margin-right: 25px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-40 {
    margin-right: 40px;
}

.mr-45 {
    margin-right: 45px;
}

.mr-50 {
    margin-right: 50px;
}
.mr-60 {
    margin-right: 60px;
}
.mr-70 {
    margin-right: 70px;
}

.mr-80 {
    margin-right: 80px;
}
.mr-100 {
    margin-right: 100px;
}

.mx-20 {
    margin: 0 20px;
}

.mx-30 {
    margin: 0 30px;
}

.mx-50 {
    margin: 0 50px;
}

.margin-0 {
    margin: 0 0 0;
}

// borders
.border-dashed {
    border: 1px dashed $border-color-dashed;
    border-radius: 4px;
}

.no-border {
    border: none;
}

// Backgrounds

.main-background {
    background-color: $bg-color-primary;
}

.transparent {
    background-color: transparent;
}

// Display
.d-block {
    display: block;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

//Padding
.pb-0 {
    padding-bottom: 0;
}

.pb-20 {
    padding-bottom: 20px;
}
.px-100 {
    padding-left: 100px;
    padding-right: 100px;
}
.py-10 {
    padding-bottom: 10px;
    padding-top: 10px;
}
.py-100 {
    padding-bottom: 100px;
    padding-top: 100px;
}
.py-20 {
    padding: 20px 0;
}
.pl-0 {
    padding-left: 0;
}
.pl-30 {
    padding-left: 30px;
}
.py-30 {
    padding: 30px 0;
}
.px-10 {
    padding: 0 10px;
}
.px-20 {
    padding: 0 20px;
}
.px-30 {
    padding: 0 30px;
}
.px-40 {
    padding: 0 40px;
}
.px-50 {
    padding: 0 50px;
}
.pt-5 {
    padding-top: 5px;
}
.pt-10 {
    padding-top: 10px;
}
.pt-20 {
    padding-top: 20px;
}
.pt-50 {
    padding-top: 50px;
}
.pr-50 {
    padding-right: 50px;
}

// Positions

.relative {
    position: relative !important;
}

// Height

.h-100 {
    height: 100%;
}
.h-30 {
    height: 30px;
}

.visible {
    visibility: visible;
}

.main-loader {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    z-index: 999;
}

.confirmation .ant-modal-content {
    display: flex;
}

.ant-btn-primary {
    color: $font-color-button-primary;
    background: $bg-color-button-primary;
    border-color: $bg-color-button-primary;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    background-color: $bg-color-button-secondary;
    border-color: $bg-color-button-primary;
    color: $font-color-button-secondary;
}

.fix-img-to-100-height {
    height: 100vh;
}

.templates-main-title {
    @extend %font-light;
    @extend %heading;
    color: $font-color-heading;
    font-size: 16px;
    letter-spacing: 0.5px;
    padding-bottom: 7px;
    // word-break: break-word;

    &::after {
        content: none;
    }
}

.after-none {
    &::after {
        content: none;
    }
}

.tree-dots {
    @extend %absolute;
    right: 20px;
    top: -30px;
    z-index: 5;

    &__map {
        top: -580px;
    }
}

.gallery-image-item {
    font-weight: 800;
}

input[type='text']:disabled {
    border: none;
    background-color: transparent;
}

.disabled {
    cursor: not-allowed !important;
}

.pointer {
    cursor: pointer;
}

.cursor-def {
    cursor: default;
}

.radio-button {
    display: none;

    &__layout {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: inline-block;
            height: 17px;
            width: 17px;
            border: 2px solid #1c3f50;
            border-radius: 2px;
            margin-right: 10px;
        }
    }

    &:checked + .radio-button__layout {
        &::before {
            border: 5px solid #1c3f50;
        }
    }
}

.text-bold {
    font-weight: bold;
}