.deadline {
    &__main {
        @extend %relative;
    }

    &__group {
        align-items: center;
        justify-content: center;
        z-index: 50;
        width: 260px;
        flex-direction: column;

        &-circle--position {
            flex-direction: row;
            justify-content: space-between;

            @include respond-to(900) {
                flex-direction: column;
                justify-content: unset;
            }
        }
    }

    &__group-circle {
        border: 1px solid $main-bg-color;
        border-radius: 50%;
        min-height: 27px;
        max-height: 27px;
        min-width: 27px;
        max-width: 27px;
        align-items: center;
        justify-content: center;
        top: 5px;
        position: absolute;

        &--grey {
            border: 1px solid $border-color-button-primary;
        }

        &--top-15 {
            position: absolute;
            left: -15px;
            top: -10px;
            @include respond-to(580) {
                left: -18px;
            }
        }
    }

    &__group-circle-small {
        background-color: $main-bg-color;
        border-radius: 50%;
        height: 12px;
        width: 12px;

        &--grey {
            background-color: $border-color-button-primary;
        }
    }

    &__group-info {
        @extend %absolute;
        top: 50px;
    }

    &__line {
        @extend %absolute;
        height: 2px;
        width: auto;
        top: 18px;
        left: 120px;
        right: 120px;
        border: 1px solid $border-color-card;
        z-index: 0;

        &--bid-summary {
            width: 2px;
            height: 90%;
            top: 20px;
            left: 20px;
            right: 0px;
            background-color: var(--mainColor6);

            @include respond-to(900) {
                height: 2px;
                width: auto;
                top: 18px;
                left: 120px;
                right: 120px;
            }
        }
    }

    &__stages {
        justify-content: space-between;
        &--bid-summary {
            flex-direction: column;
            gap: 100px;
            padding-left: 2px;

            @include respond-to(900) {
                flex-direction: row;
                padding-left: 0;
            }
        }
    }

    &__stage-container {
        @include respond-to(580) {
            margin-top: 20px;
        }
    }

    &__stage {
        @extend %font-bold;
        @extend %relative;
        font-size: 16px;
        color: $font-color-primary;
    }

    &__info {
        @extend %font-medium;
        font-family: $font-family-secondary;
        font-size: 10px;
        color: $font-color-tabs;
    }

    &__date {
        @extend %font-medium;
        font-family: $font-family-secondary;
        font-size: 10px;
        color: $bg-color-active-tab;
    }

    &__bid-summary {
        @extend %font-medium;
        color: $font-color-tabs;
        font-size: 18px;
        font-weight: 300;

        &--dark {
            color: $bg-color-active-tab;
        }

        &--mt-40 {
            margin-top: 0;
            text-align: right;
            @include respond-to(900) {
                text-align: unset;
                margin-top: 40px;
            }
        }
    }
}
.dealine__ant-steps {
    flex-wrap: wrap;

    @include respond-to(580) {
        row-gap: 50px;
    }

    .ant-steps-item-finish {
        & > .ant-steps-item-container {
            & > .ant-steps-item-tail::after {
                background-color: $border-color-input;
            }
        }
    }
}
.create-deal-timeline {
    position: relative;
    z-index: 1000;
}
