.container-search {
    padding: 60px 0;
    min-height: calc(100vh - 505px);

    .property-card__container {
        max-width: 400px;
        img {
            height: 230px;
        }
    }

    &__inputs-container {
        & div {
            & > div {
                .ant-select-selector {
                    border: none !important;
                }
            }
        }
    }

    &__relative-cont {
        @extend %relative;
    }

    &__form {
        @extend %absolute;
        bottom: 0;
        z-index: 2;
        background-color: $bg-color-button-secondary;
        left: 50%;
        transform: translate(-50%, 50%);
        border-radius: 5px;
    }

    &__input {
        border: none;
        width: 100%;

        &:focus {
            outline: none;
        }
    }

    &__group {
        padding: 5px 20px;

        &--border-right {
            border-right: 1px solid $border-color-card;
        }

        &--first {
            width: 300px;
        }
    }

    &__btn {
        display: block;
    }

    &__inputs-container {
        border: 1px solid $border-color-input;
        border-right: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
}

.search-property {
    display: flex;
    flex-wrap: wrap;

    &__item {
        flex: 0 0 30%;
        cursor: pointer;
    }
}

.search-assets {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    margin: 80px auto 0 auto;
    gap: 20px;
    padding-right: 5px;

    &__item {
        flex: 0 0 33%;
    }
}
