.building-cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    max-width: 1262px;
    margin: auto;
    border-bottom: 1px solid $border-color-card;
    padding-bottom: 50px;

    @include respond-to(680) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
    @include respond-to(1024) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.building-cards > .property-card__container {
    max-width: 100%;

    @include respond-to(738) {
        margin: 20px;
        max-width: 360px;
    }
}
