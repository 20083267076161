.marketing-timeline {
    &__input-width {
        width: 190px;
    }

    &__disabled {
        cursor: not-allowed;
        &:hover {
            background-color: $bg-color-button-primary;
            color: $bg-color-button-secondary;
        }
    }
}

.marketing-timeline label {
    @extend %font-medium;
    color: $font-color-label;
    font-family: $font-family-2;
    font-size: 12px;
}
