.radio-list {       
        margin-left: 15px;
        margin-bottom: 40px;

        &-button > label {
            @extend %font-medium;
            font-family: $font-family-2;
            color: $font-color-primary;
            text-transform: none;
            letter-spacing: 0;
            margin-bottom: 0;
            font-size: 14px;
        }
        &-button {
            padding: 0px 10px;
        }
       
        &-button > input {
            margin-right: 10px;
        }
}