.biding {
    & label {
        margin-top: 20px;
    }

    &__gap {
        gap: 40px;
    }

    &__type-document {
        max-width: 500px;
        flex-wrap: wrap;
    }

    &__single {
        flex: 0 0 50%;
    }

    &__lable {
        font-size: 16px;
    }

    &--padding-0 {
        padding: 0;
    }
}

.biding .checkbox > input:checked + span::before {
    top: -2px;
    font-size: 19px;
    left: 1px;
}

.biding .checkbox > input {
    width: 15px;
    height: 15px;
}

.biding .ant-upload.ant-upload-select {
    width: 485px;
    display: block;
}

.biding .ant-upload p {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed $font-color-primary;
    text-align: center;
    border-radius: 10px;
    height: 50px;
    cursor: pointer;
}

.biding .ant-upload-span {
    width: 54%;
    margin-top: 20px;
    margin-bottom: 40px;
}
