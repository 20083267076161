.contact-form-modal .ant-modal-content {
    display: flex;
    max-height: 700px;
    width: 676px;
    padding: 15px 25px;
}

.contact-form-modal textarea {
    height: 120px;
    resize: none;
    margin-bottom: 20px;
}

.research {
    &__radio {
        width: 100%;
        margin-left: 15px;
        margin-bottom: 40px;

        &-button > label {
            @extend %font-medium;
            font-family: $font-family-2;
            color: $font-color-primary;
            text-transform: none;
            letter-spacing: 0;
            margin-bottom: 0;
            font-size: 14px;
        }

        &-button > input {
            margin-right: 10px;
        }
    }
}
