.navigation {
    &__container {
        background-color: var(--header-bg-color);
    }
    &__lg-menu {
        display: none;

        a {
            color: $bg-color-footer;
            font-size: 14px;
        }

        button {
            color: $bg-color-footer;
        }

        @include respond-to(783) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__top-links {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
    }

    &__line {
        display: inline-block;
        width: 1px;
        height: 20px;
    }
}
