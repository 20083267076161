.marketing {
    padding-top: 80px;
    padding-left: 25px;

    &__nav {
        padding: 0;
        margin: 0;
    }

    &__company-logo {
        align-items: center;
    }

    &__company-logo-img {
        width: 60px;
        margin-right: 10px;

        &--icon {
            font-size: 30px;
            color: $font-color-tabs;
        }
    }

    &__navigation-name {
        @extend %font-medium;
        color: $font-color-secondary;
    }

    &__body-search {
        margin: 10px 10px 40px 0;
        &-top {
            flex: 1;
            flex-direction: column;
        }
    }

    &__body-search-portfolio {
        margin: 30px 40px 10px 0;
        &-top {
            flex: 1;
            flex-direction: column;
            border: 2px solid $border-color-input;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-right: none;
            padding: 10px 0;

            &:focus {
                background: $font-color-heading-text;
            }
        }
    }

    &__btn {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        max-width: 87px;
        height: auto;
    }

    &__plus {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0 auto;

        &--green {
            background-color: $bg-underline-op50;
            border: 1px solid $border-color-radio-selected;
            display: flex;
            align-items: center;
            justify-content: center;

            &::after {
                content: '';
                display: block;
                height: 10px;
                width: 20px;
                border-left: 1px solid $border-color-radio-selected;
                border-bottom: 1px solid $border-color-radio-selected;
                transform: translateY(-3px) rotate(-45deg);
            }
        }

        &--bid-sum {
            background-color: $bg-underline-op;
            border: 1px solid $bg-underline-op;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-10px);
            margin: 0;

            &::after {
                content: '';
                display: block;
                height: 10px;
                width: 20px;
                border-left: 1px solid $border-color-radio-selected;
                border-bottom: 1px solid $border-color-radio-selected;
                transform: translateY(-3px) rotate(-45deg);
            }

            @include respond-to(900) {
                margin: 0 auto;
                margin-top: 10px;
            }
        }

        &--center {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__label {
        @extend %font-medium;
        color: $font-color-primary;
        font-size: 16px;
        margin: 0;
        padding: 0 0 0 30px;
    }

    &__input {
        padding: 0 0 0 30px;

        &:focus {
            border: none;
            outline: none;
        }
    }

    &__input-portfolio {
        padding: 0 0 0 30px;
        border: none;
        height: 20px;
        &:focus {
            border: none;
            outline: none;
        }
    }

    &__companes {
        flex-wrap: wrap;
        gap: 45px;
    }

    &__company {
        position: relative;
        flex: 0 0 30%;
        flex-direction: column;
        gap: 10px;
        padding: 20px 20px 0 20px;
        box-shadow: 0 0 20px 0 rgba(182, 218, 213, 0.2);
    }

    &__user {
        flex: 30%;
        max-width: 272px;
    }

    &__company-group {
        flex-direction: column;
        gap: 5px;

        &--marg {
            margin-bottom: 8px;
        }

        &--marg-t {
            margin-top: 50px;
        }

        &--butt {
            left: 45%;
            position: absolute;
            bottom: -20px;
        }

        &--butt-worker {
            position: relative;
            bottom: -20px;
        }
    }

    &__company-title {
        @extend %font-medium;
        color: $font-color-tabs;
        font-size: 10px;
        font-family: $font-family-secondary;
        text-transform: uppercase;

        &--sub {
            color: $font-color-secondary;
            font-size: 12px;
            font-weight: 400;
            text-transform: unset;
        }

        &--name {
            font-family: $font-family-primary;
            font-size: 20px;
            text-transform: unset;
            color: $font-color-primary;
        }

        &--industry {
            font-size: 11px;
            color: $font-color-primary;
            text-transform: unset;
        }
    }

    &__company-logo-info {
        flex-direction: column;
    }
}

.marketing-ant {
    .ant-tabs {
        .ant-tabs-nav {
            max-width: none !important;
            width: 100% !important;
            height: 100% !important;
            min-height: 0 !important;
            padding-top: 0px !important;
            padding-left: 0px;

            .ant-tabs-nav-wrap {
                .ant-tabs-nav-list {
                    .ant-tabs-tab {
                        margin-top: 20px;
                        margin-bottom: 0px;
                        padding: 20px 10px;
                    }
                    .ant-tabs-ink-bar {
                        display: block;
                    }
                }
            }
        }
    }
}

.marketing-table {
    .ant-table-wrapper {
        // display: none;
        .ant-spin-nested-loading {
            .ant-spin-container {
                .ant-table {
                    .ant-table-container {
                        .ant-table-content {
                            table {
                                tr {
                                    th {
                                        color: $font-color-primary;
                                        font-family: Rubik;
                                        font-size: 12px;
                                        font-weight: 500;
                                        letter-spacing: 0;
                                        line-height: 14px;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__table-tr {
        color: $font-color-primary;
        font-family: Rubik;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
    }
}
