.faq {
    &__questions {

        .ant-collapse {
            background-color: white;
            font-size: 12px;

            .ant-collapse-item-active .radio-button__layout::before {
                border: 5px solid #1c3f50;
            }
        }
    }
}
.faq-form {
    display: flex;
}

.faq-tabs .ant-tabs-tab p {
    font-size: 12px;
    @extend %font-medium;
    font-family: $font-family-1;
    color: $font-color-label;
}

.faq-tabs > .ant-tabs .ant-tabs-nav {
    background-color: $font-color-light;
    border-bottom: none;
}

.faq-tabs .ant-tabs-ink-bar {
    height: 5px;
    background: white !important;
}

.faq-tabs .ant-tabs-nav {
    border-right: 1px solid lightgray;
}

.faq-tabs .ant-tabs-nav {
    padding-top: 37px;
    padding-right: 50px;
}

.faq-tabs .ant-tabs-tabpane {
    padding-left: 60px !important;
}

.faq-m {
    margin-left: auto;
}

.questions {
    width: 100%;

    &-content {
        &__question {
            // width: 700px;
            max-height: 400px;
            border-bottom: 1px solid lightgray;
            margin-bottom: 20px;
        }

        &__title {
            @extend %font-medium;
            font-family: $font-family-1;
            font-size: 12px;
            line-height: 28px;
            margin-bottom: 20px;
        }

        &__question p {
            margin-bottom: 15px;
            font-size: 10px;
        }

        &__answer {
            // margin-bottom: 30px;
            // width: 700px;
        }

        &__answer p {
            font-size: 10px;
        }
    }
}

.faqs-tabs-tab-name {
    max-width: 350px;
    width: 350px;
    overflow: hidden;
}

.faq {
    &-form > input {
        height: 60px;
        width: 100%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0 0 8px 0 rgba(215, 222, 227, 0.39);
    }

    &-form > input:focus {
        outline: none;
    }

    &-form > button {
        height: 60px;
        width: 60px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .ant-tabs-tab-active {
        .radio-button__layout {
            &::before {
                border: 5px solid #1c3f50 !important;
            }
        }
    }
}

.topic-header {
    width: 100%;
    justify-content: space-between;
}
