.dealine {
    .add-property-image-builder__upload {
        &--margin-x {
            margin: 0 auto;
        }
        position: relative;
        .ant-btn {
            margin-top: 0;
        }
    }
}
