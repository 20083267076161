.table-header {
    @extend %font-regular;
    color: $font-color-primary !important;
    font-family: $font-family-2;
    font-size: 18px;
    letter-spacing: 0.9px;
    line-height: 48px;
    text-align: left !important;
    padding-left: 25px;
    padding-top: 15px;
}

.teams-table {
    @at-root &__wrap {
        @extend %relative;
        border-radius: 8px;
        background-color: white;
        margin: 20px 50px 80px 50px;

        .ant-table-cell {
            color: $font-color-primary;
        }
    }

    &__checkbox-container {
        @extend %absolute;
        top: -100px;
        &-mt {
            margin-top: 7px;
        }
    }

    &__button-action {
        border-radius: 4px;
        border: 1px solid $bg-color-button-primary;
        color: $bg-color-button-primary;
        padding: 5px;
        cursor: pointer;
        line-height: normal;
        height: 25px;
    }

    &__table {
        .ant-spin-nested-loading {
            .ant-spin-container {
                .ant-table {
                    .ant-table-container {
                        .ant-table-content {
                            overflow-y: scroll;
                        }
                    }
                }
            }
        }
    }
    &__dropdown-table {
        background-color: $font-color-header-light;
    }
}
.editable-cell-value-wrap {
    min-height: 22px;
}

.teams-export {
    cursor: pointer;

    &__text {
        @extend %font-medium;
        font-family: $font-family-1;
        color: $font-color-button-secondary;
        font-size: 10px;
        line-height: 18px;
        text-decoration: underline;
    }

    &__icon {
        color: $font-color-button-secondary;
        margin: 3px 20px 0 5px;
    }
}

.teams-button {
    box-shadow: none;
    &:hover {
        background-color: $bg-main-button;
        .teams-export__icon {
            color: $font-color-footer;
        }
    }

    &--pading-left {
        padding-left: 20px;
    }
}

.add-user-modal .ant-modal-content {
    display: flex;
    min-height: 440px;
    max-height: 550px;
    width: 530px;
    padding: 15px 25px;
}

.add-investor-modal .ant-modal-content {
    display: flex;
    min-height: 400px;
    max-height: 400px;
    width: 676px;
    padding: 15px 25px;
    .ant-modal-body {
        width: 100%;
    }
}

.selected {
    height: 30px;
    border: 1.12px solid $font-color-button-secondary;
    border-radius: 15px;
    background-color: #ffdbd9;
    font-weight: 300;
    color: $font-color-button-secondary;
    font-family: $font-family-1;
    font-size: 10px;
    padding: 0 10px;
    min-width: 90px;
}

.unselected {
    @extend %font-light;
    color: $border-color-secondary;
    background-color: $font-color-light;
    font-family: $font-family-1;
    min-height: 30px;
    min-width: 90px;
    max-width: fit-content;
    border: 1px solid $border-color-secondary;
    border-radius: 15px;
    font-size: 10px;
    padding: 0 10px;
    cursor: pointer;
}

.teams {
    &__button-container {
        padding: 30px 0;
        background-color: $bg-color-primary;
    }

    &__card-cotnainer {
        flex-wrap: wrap;
    }

    &__drop-down-menu {
        button {
            border: 0;
            margin-top: 2px !important;
        }
        button:first-child {
            display: none;
        }
    }

    .active-mode-icon {
        color: $bg-main-button;
    }
}

.list-nda {
    .ant-modal-content {
        width: 600px;
    }
    &__actions {
        color: $bg-color-button-primary;
        cursor: 'pointer';
        font-weight: 'bolder';
    }

    &__h-line {
        height: 16px;
        width: 1px;
        background-color: $border-color-checkbox;
    }

    &__main--single {
        padding-bottom: 10px;
        border-bottom: 1px solid $border-color-checkbox;
    }

    &__dots {
        color: $border-color-checkbox;
        cursor: pointer;
    }

    &__main-text {
        color: $border-color-checkbox;

        &--version {
            @extend %font-medium;
            color: $border-color-dashed;
        }
    }
}

.user-cards {
    .user {
        border-radius: 10px;
        border: 1px solid #1c4050f1;
    }

    .user.wrap-cards {
        flex: 0 0 32%;
    }

    .user:not(:last-child) {
        margin-bottom: 30px;
    }

    .user .user-property:not(:last-child) {
        margin-bottom: 10px;
    }

    .ant-card {
        font-family: $font-family-2;

        .ant-card-head-title {
            text-align: center;
        }

        .ant-card-head {
            border-bottom: 1px solid #1c4050f1;
        }
    }
}