.dlt-transaction {
    &__quote {
        font-size: 24px;
        padding: 0 50px;
        transform: translateY(50px);
        opacity: 0;
        transition: 0.5s all ease-in-out;

        &--show {
            opacity: 1;
            transform: translateY(0px);
        }
    }
}
