.ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
        background-color: $bg-color-button-secondary;
        border-color: $border-color-input;
        border-radius: 5px;
        min-height: 40px;
        cursor: pointer !important;
        font-size: 10px;
        color: $font-color-input;

        .ant-select-selection-search-input {
            height: 40px;
            cursor: pointer !important;
        }

        .ant-select-selection-item {
            margin: auto;
        }
    }
}

.ant-select-focused:not(.and-select-disabled).ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
        border-color: $border-color-input;
        -webkit-box-shadow: 0 0 0 0 rgb(24 144 255 / 20%);
        box-shadow: 0 0 0 0 rgb(14 144 255 / 20%);
    }
}

.ant-select:not(.ant-select-disabled):hover {
    .ant-select-selector {
        border-color: $border-color-input;
    }
}

.ant-select-selection-placeholder {
    @extend %font-regular;
    color: $font-color-input;
    font-family: $font-family-secondary;
    font-size: 10px;
    margin: auto;
}

.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
        height: 40px;
        color: $font-color-input;
    }
}

.ant-select-selection-search-input {
    height: 40px;
}

.ant-select-item-option-content {
    @extend %font-regular;
    display: flex;
    color: $font-color-input;
    font-family: $font-family-secondary;
    align-items: center;
    white-space: break-spaces;
}

.select-checkbox {
    @extend %relative;
    border: 1.5px solid $main-bg-color;
    border-radius: 2px;
    height: 17px;
    width: 17px;
    min-width: 17px;
    margin-right: 10px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: transparent;

    .select-checkbox {
        border-color: $main-bg-color;
        &:after {
            @extend %absolute;
            content: '';
            border-bottom: 2px solid $main-bg-color;
            border-right: 2px solid $main-bg-color;
            top: 1px;
            bottom: 0;
            left: 4px;
            height: 10px;
            width: 5px;
            transform: rotate(45deg);
        }
    }
}

.rc-virtual-list-holder-inner {
    background-color: $bg-color-button-secondary;
    border-color: $border-color-input;
    border-radius: 5px;
}

.ant-select-item-option-state {
    display: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: transparent;
}
