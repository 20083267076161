@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

@font-face {
    font-family: 'AirbnbCerealApp';
    src: url('../../fonts/AirbnbCerealBold.eot');
    src: url('../../fonts/AirbnbCerealBold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/AirbnbCerealBold.woff2') format('woff2'),
    url('../../fonts/AirbnbCerealApp-Bold.woff') format('woff'),
    url('../../fonts/AirbnbCerealApp-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'AirbnbCerealApp';
    src: url('../../fonts/AirbnbCerealBook.eot');
    src: url('../../fonts/AirbnbCerealBook.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/AirbnbCerealBook.woff2') format('woff2'),
    url('../../fonts/AirbnbCerealApp-Book.woff') format('woff'),
    url('../../fonts/AirbnbCerealApp-Book.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'AirbnbCerealApp';
    src: url('../../fonts/AirbnbCerealLight.eot');
    src: url('../../fonts/AirbnbCerealLight.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/AirbnbCerealLight.woff2') format('woff2'),
    url('../../fonts/AirbnbCerealApp-Light.woff') format('woff'),
    url('../../fonts/AirbnbCerealApp-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'AirbnbCerealApp';
    src: url('../../fonts/AirbnbCerealMedium.eot');
    src: url('../../fonts/AirbnbCerealMedium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/AirbnbCerealMedium.woff2') format('woff2'),
    url('../../fonts/AirbnbCerealApp-Medium.woff') format('woff'),
    url('../../fonts/AirbnbCerealApp-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
}

  