.pricing {
    padding-top: 80px;

    &__hero {
        @extend %relative;
        padding: 80px 0 240px 0;

        &--image {
            @extend %absolute;
            top: 0;
            height: 100%;
            z-index: -1;
        }
    }

    &__lineHight {
        text-align: center;
        line-height: 30px;
        width: 200px;
    }

    &__hero-info {
        padding: 10px 15px 20px 15px;
        background-color: $bg-pricing-hero;
        width: 600px;

        &-heading {
            color: $bg-color-button-secondary;
            text-align: center;
            &::after {
                content: none;
            }
        }
        &-paragraph {
            color: $bg-color-button-secondary;
            text-align: center;
        }
    }

    &__main {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        &--small-width {
            margin: 0 auto;
            width: 70%;
        }
    }

    &__card {
        padding: 10px 35px 25px 35px;
        border-bottom: 2px solid $border-color-radio-selected;
        box-shadow: 4px 0 10px 0 rgba(28, 64, 80, 0.1);
        height: 265px;
        transition: height 1s ease;

        &-heading {
            color: $border-color-footer;
            text-align: center;

            &::after {
                content: none;
            }
        }

        &-paragraph {
            color: $bg-color-active-tab;
            font-size: 12px;
            text-align: center;
        }

        &-price {
            font-size: 30px;
            color: $bg-color-active-tab;

            &--small {
                color: $border-color-footer;
                font-size: 15px;
            }
        }

        &-buy-btn {
            @extend %font-medium;
            background-color: $font-color-bid-modal-info;
            border: 1px solid $font-color-bid-modal-info;
            color: $bg-color-primary;
            width: 130px;
            height: 35px;
            border-radius: 7px;
        }

        &__list {
            padding-bottom: 20px;
            border-bottom: 1px solid $border-color-footer;

            &--right {
                border-right: 1px solid $border-color-footer;
            }
        }

        &:hover {
            height: 285px;
        }
    }
    &__checkout {
        &-single {
            font-size: 14px;
            display: flex;
            gap: 10px;

            &::before {
                content: '\2713';
                color: $bg-calendar-day;
            }
        }
        &--heading {
            color: $border-color-footer;
            text-align: center;

            &::after {
                content: none;
            }
        }
    }

    &__more-cotnainer {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__more-info-card {
        flex: 50%;
        padding: 0 80px;

        &--center {
            margin: 20 auto;
        }
    }

    &__card-paragraph--size {
        font-size: 14px;
    }
}
