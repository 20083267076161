.home-page {
    &-header {
        @extend %relative;
        height: 100vh;
        overflow: hidden;

        &__rolling-text {
            @extend %font-bold;
            font-size: 32px;
            text-align: center;
            color: $font-color-footer;
            animation-name: popUp;
            animation-duration: 8s;
            animation-iteration-count: infinite;
            z-index: 2;
        }

        &__rolling-text-container {
            max-width: 80%;
        }

        &__btn {
            background-color: transparent;
            color: $bg-color-button-secondary;
            border: 1px solid $bg-color-button-secondary;
            padding: 5px 20px;
            cursor: pointer;

            &:hover {
                color: $bg-color-button-secondary;
            }
        }

        &__video {
            @extend %absolute;
            z-index: -1;

            height: 100vh;

            @include respond-to(800) {
                height: 1200px;
            }

            @include respond-to(1400) {
                height: 1200px;
            }
            @include respond-to(2100) {
                height: 1500px;
            }
        }

        @keyframes popUp {
            0% {
                opacity: 0;
                transform: translateX(-100px);
            }
            50% {
                opacity: 1;
                transform: translateX(0px);
            }
            100% {
                opacity: 0;
                transform: translateX(100px);
            }
        }
    }

    &-about {
        width: 60%;
        margin: 0 auto;

        &__heading {
            @extend %font-bold;
            font-size: 32px;
            text-align: center;
            color: $font-home-page-about-heading;
        }

        &__subtitle {
            text-align: center;
            font-size: 14px;
            color: $font-home-page-about-button;
        }

        &__play-button {
            display: block;
            margin: 0 auto;
            text-transform: uppercase;
            border: 0;
            background: none;
            color: $font-home-page-about-button;
            border-bottom: 1px solid $font-home-page-about-heading;
            padding: 5px 30px;
            cursor: pointer;
        }

        &__video-container {
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.385);
            z-index: 5;

            &-black {
                width: 60%;
                margin: 0 auto;
                height: 50vh;
            }

            &-video {
                height: 100%;
                width: 100%;
            }
        }

        &__cross {
            margin-left: auto;
            font-size: 25px;
            cursor: pointer;
        }
    }

    &-planet {
        @extend %relative;
        padding-top: 80px;
        padding-bottom: 100px;
        min-height: 80vh;

        &__image {
            @extend %absolute;
            z-index: -1;
        }

        &__card {
            z-index: 1;
            gap: 20px;
            width: 100%;
            border: 1px solid $font-home-page-about-heading;
            border-radius: 4px;
        }

        &__content {
            background-color: $bg-color-button-secondary;
            border-radius: 4px;
            padding: 40px;
            width: 30%;
            margin: 80px auto 0 auto;
            box-shadow: 0 0 20px 0 rgba(182, 218, 213, 0.3);
            cursor: pointer;
        }

        &__title {
            color: $font-home-page-about-button;
            font-size: 16px;
            text-align: center;

            &-sm {
                font-size: 14px;
                text-align: center;
            }
        }

        &__icon {
            font-size: 20px;
            color: $font-home-page-about-heading;
        }

        &__card-sub {
            font-size: 14px;
            color: $font-home-page-about-button;
        }

        &__card-title {
            @extend %font-bold;
            font-size: 16px;
            color: $font-home-page-about-button;
        }
    }
}
