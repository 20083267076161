.choose-layout__radio {
    background-size: cover;
    background-position: center;
    width: 100%;

    .property__radio-option {
        top: 3px;
        right: 17px;
    }
}
.chart__overflow {
    overflow: hidden;
    overflow-x: scroll;
}
.rich-text-editor-container {
    margin-bottom: 35px;
    .wizard-heading {
        margin-bottom: 25px;
    }

    .ant-upload-list {
        display: none;
    }
}

.ck-editor__editable {
    min-height: 200px;
    margin-bottom: 10px;
}

.permission-form {
    width: 100%;
    label {
        @extend %relative;
        display: block;

        p {
            margin-left: 25px;
            font-size: 12px;
        }
    }

    label input {
        @extend %absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    span {
        @extend %absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        border: 1px solid $border-color-input;
    }

    label input:checked ~ span {
        border: 1px solid $border-color-button-primary;
    }

    span:after {
        content: '';
        @extend %absolute;
        display: none;
    }

    label input:checked ~ span:after {
        display: block;
    }

    label span:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid $border-color-button-primary;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    h3 {
        color: $font-color-primary;
        font-size: 18px;
        margin-bottom: 20px;
    }
}

.photo {
    &__trash {
        @extend %absolute;
        bottom: 90%;
        left: 87%;
        color: $font-color-sun;
    }
    &__pencil {
        @extend %absolute;
        bottom: 90%;
        left: 10%;
        color: var(--buton-bg-color);
        background-color: $bg-color-button-secondary;

        &:hover {
            color: $bg-color-button-secondary;
            background-color: var(--buton-bg-color);
        }

        &--rich-text {
            top: 75px;
            bottom: none;
            left: 85%;
            z-index: 5;
            font-size: 16px;
        }

        &--trash {
            color: $bg-color-button-primary;
            background-color: $bg-color-button-secondary;
            top: 75px;
            bottom: none;
            left: 5%;
            z-index: 5;
            font-size: 16px;

            &:hover {
                color: $bg-color-button-secondary;
                background-color: $bg-color-button-primary;
            }
        }

        &--gallery {
            top: 20px;
            bottom: none;
            left: 80%;
            z-index: 5;
            font-size: 16px;
        }

        &--gallery-trash {
            color: var(--buton-bg-color);
            background-color: $bg-color-button-secondary;
            top: 20px;
            bottom: none;
            left: 7%;
            z-index: 5;
            font-size: 16px;

            &:hover {
                color: $bg-color-button-secondary;
                background-color: var(--buton-bg-color);
            }
        }
    }
}
