.portfolio {
    padding-top: 80px;
    padding-left: 50px;

    &__main {
        gap: 30px;
        flex-wrap: wrap;
    }

    &__tree-dots-top {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
    }

    &__single-element {
        @extend %relative;
        flex: 0 0 30%;
    }

    &__single-element-button {
        @extend %absolute;
        left: 50%;
        bottom: 2%;
        transform: translateX(-50%);
    }

    &__upload {
        position: relative !important;
        justify-content: start !important;
    }
}
