// Font family
$font-family-1: 'AirbnbCerealApp';
$font-family-2: 'Rubik', sans-serif;

// Background
$bg-color-primary: var(--mainColor6);
$bg-color-button-primary: var(--mainColor5);
$bg-color-button-secondary: var(--mainColor7);
$bg-color-footer: var(--mainColor8);
$bg-color-active-tab: var(--mainColor3);
$bg-color-input: var(--mainColor6);
$bg-underline: var(--mainColor13);
$bg-underline-op: var(--mainColor13Op);
$bg-underline-op50: var(--mainColor13Op50);
$bg-color-radio-selected: var(--mainColor14);
$bg-color-dot: var(--mainColor16);
$bg-color-panding-approval: var(--mainColor9Op);
$bg-color-icon: var(--mainColor20);
$bg-calendar-day: var(--mainColor22);
$bg-calendar-day-op: var(--mainColor22Op);
$bg-pricing-hero: var(--mainColor8Op);
$bg-dlt-header: var(--mainColor8);
$bg-dlt-cards: var(--mainColor46);
$bg-idle: var(--mainColor3Op);
$bg-dlt-transactions-light-grey: var(--mainColor47);
$bg-investors-table: var(--mainColor6);
$bg-investors-table-head: var(--mainColor6);
$bg-overview-card-logo: var(--mainColor5);
$bg-overview-card-logo-light: var(--mainColor5Op);

// Borders
$border-color-button-primary: var(--mainColor5);
$border-color-button-primary-light: var(--mainColor5Op);
$border-color-footer: var(--mainColor9);
$border-color-input: var(--mainColor6);
$border-color-checkbox: var(--mainColor2);
$border-color-card: var(--mainColor11);
$border-color-secondary: var(--mainColor9);
$border-color-radio-selected: var(--mainColor13);
$border-color-dashed: var(--mainColor4);
$bolder-color-button-light-virgate-com: var(--mainColor8);

//Box Shadow
$header-shadow: var(--mainColor7);
$bg-overview-card-shadow: rgba(182, 218, 213, 0.3);
$bg-overview-card-selected-shadow: rgba(32, 58, 70, 0.5);

// Typography
$font-family-primary: $font-family-1;
$font-family-secondary: $font-family-2;

// Font colors
$font-color-primary: var(--mainColor4);
$font-color-secondary: var(--mainColor3);
$font-color-heading: var(--mainColor3);
$font-color-heading-text: var(--mainColor6);
$font-color-button-primary: var(--mainColor6);
$font-color-button-secondary: var(--mainColor5Op);
$font-color-header-light: var(--mainColor1);
$font-color-footer: var(--mainColor1);
$font-color-tabs: var(--mainColor2);
$font-color-label: var(--mainColor3);
$font-color-input: var(--mainColor49);
$font-color-error: var(--mainColor5);
$font-color-light: var(--mainColor6);
$font-color-table-row: var(--mainColor17);
$font-color-date: var(--mainColor9);
$font-color-schedule: var(--mainColor22Op);
$font-color-sun: var(--mainColor5);
$font-color-sat: var(--mainColor26);
$font-color-days: var(--mainColor27);
$font-color-status-panding: var(--mainColor28);
$font-color-status-panding-op: var(--mainColor27Op);
$font-color-bid-modal-info: var(--mainColor30);
$font-statistic-info: var(--mainColor35);
$font-home-page-about-heading: var(--mainColor43);
$font-home-page-about-button: var(--mainColor3Op70);
$font-close-button: var(--mainColor3);
$font-color-side-menu-number: var(--mainColor48);

//COLORS FOR THEME
$main-bg-color: var(--main-bg-color);
$main-text-color: var(--main-text);
$tab-text: var(--asset-bar-text);
$main-text-color-op: var(--main-text-op);

$bg-main-button: $main-bg-color;
$bg-secondary-button: $main-text-color;
$slide-in-bg-color: $main-bg-color;
$slide-in-text-color: $main-text-color;
$wizard-bg-color: $main-bg-color;
$wizard-text-color: $main-text-color;
$tab-active-underline: $main-bg-color;
$tab-text-underline: $main-bg-color;
$tab-active-text: $main-bg-color;

:export {
    bgColorPrimary: $bg-color-primary;
    bgColorButtonSecondary: bg-color-button-secondary;
    green: $border-color-radio-selected;
    red: $font-color-sun;
}
