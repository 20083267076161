.wrapper {
    display: flex;
    flex-direction: column;

    .data-room-upload {
        .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
            color: #fd615a;
        }

        .ant-upload-drag {
            padding: 20px;
        }
    }
}

.data-room {
    border-radius: 8px;
    background-color: white;
    margin: 0px 50px 0px 50px;

    &__nodes-container {
        height: 60vh;
        overflow-y: scroll;
    }

    @at-root &-header {
        background: #f9fafc;
        font-size: 10px;
        font-weight: bold;
        color: #6e7189;
        height: 55px;
        line-height: 55px;

        .column-name {
            display: inline-block;
            margin-left: 74px;
        }

        .column-lastModified {
            display: inline-block;
            right: 150px;
            position: absolute;
        }
    }

    .ant-tree.ant-tree-directory .ant-tree-treenode {
        height: 55px;

        @at-root &-selected::before {
            background: $bg-underline-op;
            color: #ffffff;
        }

        @at-root &-selected .ant-tree-title {
            color: #ffffff;
        }

        .modified-date {
            position: absolute;
            right: 100px;
        }
    }

    .ant-tree .ant-tree-node-content-wrapper {
        display: flex;
    }

    .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
        display: inline-block;
        width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        vertical-align: center;
    }

    .folder__dots {
        @extend %absolute;
        right: 15px;
        top: -8px;
        background: transparent;
        border: none;
        font-size: 25px;
        color: $border-color-secondary;
        cursor: pointer;
    }

    .context-menu {
        @extend %absolute;
        right: 15px;

        .ant-dropdown-trigger {
            height: 50px;
            background: transparent !important;
            border: none;
        }
    }

    .ant-tree-title {
        font-size: 12px;
        display: inline-block;
        color: #6e7189;
        line-height: 50px;
        display: block;
        width: 100%;
    }

    .anticon {
        line-height: 54px;
        font-size: 24px;
    }

    .anticon-setting {
        line-height: 14px;
        font-size: 14px;
    }

    @at-root &-spinner-container {
        position: relative;
    }

    @at-root &-spinner {
        @extend %absolute;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        color: $font-color-button-secondary;
    }

    .ant-tree-checkbox {
        margin-top: 15px;
    }

    &__icon {
        height: 20px;
        width: 20px;
    }

    &__pdf {
        color: #ed653b;
    }

    &__doc {
        color: #3639eb;
    }

    &__xls {
        color: #1c942a;
    }

    &__zip {
        color: #841899;
    }

    &__ppt {
        color: #bd092d;
    }

    &__txt {
        color: #dbd5d6;
    }

    &__img {
        color: #c0dced;
    }

    &__pointers {
        @extend %font-light;
        font-size: 18px;
        cursor: pointer;
    }

    &__containers-pointers {
        span:last-child {
            @extend %font-regular;
            font-size: 19px;
        }
    }

    &__button-icon {
        color: $bg-main-button;
    }

    &__buttons-container {
        & button {
            width: 100px;
            display: flex;
            align-items: center;
            gap: 5px;

            &:hover {
                .data-room__button-icon {
                    color: $font-color-header-light;
                }
            }
        }
    }

    &__main-component {
        position: fixed !important;
        top: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
        background-color: rgba(1, 1, 1, 0.2) !important;

        &--hidden {
            visibility: hidden !important;
        }

        &--visible {
            visibility: visible !important;
            z-index: 999;
        }
    }

    &__no-nodes {
        height: 208px;
        width: 208px;
    }

    &__first-node {
        cursor: default !important;

        .ant-tree-node-content-wrapper {
            cursor: default !important;
        }

        .ant-tree-node-content-wrapper-normal {
            cursor: default !important;
        }

        &::before {
            display: none;
        }
    }

    &__first-node-info {
        &-owner {
            margin-right: 10px;
        }

        &-size {
            display: block;
            width: 80px;
            text-align: right;
            margin-right: 30px;
        }

        &-date {
            width: 105px;
            text-align: right;
        }
    }

    &__tree-dots {
        display: block;

        @include respond-to(1280) {
            display: none;
        }
    }

    &__main-btn-cont {
        display: none;

        @include respond-to(1280) {
            display: flex;
        }
    }
}

.search {
    &-form-screen {
        &--sm {
            width: 100%;

            @include respond-to(900) {
                width: 100%;
                display: block;
            }
        }

        &--bg {
            display: block;

            @include respond-to(900) {
                display: none;
            }
        }
    }

    &-form>input {
        height: 45px;
        width: 100%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0 0 8px 0 rgb(215 222 227 / 39%);
    }

    &-form {
        &--flex-1 {
            flex: 1;
        }
    }

    &-form>input:focus {
        outline: none;
    }

    &-form>button {
        height: 45px;
        width: 60px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.confirm-delete .ant-modal-content {
    display: flex;
    height: auto;
    padding: 15px 25px;
    justify-content: center;
}

.share-with-modal {
    width: 620px !important;
}

.share-with-modal .ant-modal-content {
    display: flex;
    min-height: 300px;
    padding: 15px 25px;
}

.data-room-buttons {
    margin-top: 20px;

    button:not(:first-of-type) {
        margin-left: 15px;
    }

    .wide {
        width: 120px;

        &:hover {
            background-color: $bg-color-button-primary;
        }

        &--big {
            width: 150px;
        }
    }
}

.data-room-permissions-container {
    width: 530px;

    .users-container {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
    }

    .data-room-permissions-container__user-permissions {
        button:first-child {
            display: none;
        }

        .ant-btn {
            margin-top: 2px;
            border: 0;
        }
    }
}

.navigation-area {
    &__main {
        gap: 20px;
    }

    &__underline {
        border-bottom: 1px solid $border-color-secondary;
    }
}

.ant-dropdown-open {
    background: transparent !important;
}

.ant-dropdown-trigger {
    background: transparent !important;
}

.dataroom-preview-file {
    .ant-modal {
        max-width: 80vw;
    }

    .ant-modal-content {
        height: 100vh;
    }

    .ant-modal-body {
        height: calc(100% - 3.5rem);
        padding: 0;
        overflow: hidden;

        & .pdf-file,
        & .word-file,
        & .image {
            height: 100%;
            width: 100%;
        }

        & .word-file {
            margin-top: -3rem;
            height: calc(100% + 3rem);
        }

        & .image {
            object-fit: contain;
        }
    }
}

@media (max-width: 992px) {
    .dataroom-preview-file {
        .ant-modal {
            max-width: 100vw;
        }
    }

    .download-button {
        text-align: center;

        div {
            margin: 1rem;
            font-size: 16px;
        }
    }
}