.not-found-and-unauthorized {
    height: 100vh;
    width: 100vw;
    background-color: $bg-idle;
    .ant-result-title {
        color: $bg-color-icon;
    }
    .ant-result-subtitle {
        color: $bg-color-icon;
    }
}
