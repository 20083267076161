.editor {
    border: 1px solid lightgray;
    border-radius: 10px;
    display: flex;
    flex-direction: row;

    textarea {
        resize: none;
        border-radius: 10px;
        flex-grow: 8;
        font-size: 14px;
        border: none;
        padding-top: 15px;
    }

    textarea:focus, textarea:active, textarea:focus-visible {
        border: none;
        box-shadow: none;
    }

    textarea::placeholder {
        font-size: 14px;
    }

    .postBtn {
        display: flex;
        margin-right: 10px;
    }

    .postBtn button {
        width: 90px;
        margin: auto 0;
        border-color: lightgrey;
        background-color: lightgrey;
        color: white;
        font-size: 12px;
        border-style: solid;
        border-radius: 5px;
        height: 80%;
        cursor: pointer;
    }
}
