.investors-table {
    border: 1px solid $bg-investors-table;
    border-radius: 4px;
    background-color: transparent;

    &__row {
        height: 32px;

        &--body {
            &:hover {
                background-color: #0000000a;
            }
        }

        &--small {
            height: 25px;
        }

        &--bg {
            background-color: $bg-investors-table-head;
        }
    }

    &__body {
        min-height: 300px;
    }

    &__row-single {
        flex: 1;
        border-bottom: 1px solid $bg-investors-table;

        &--body {
            display: flex;
            font-size: 10px;
            align-items: center;
            padding-left: 17px;
        }

        &--actions {
            flex: 0 0 80px;
            font-size: 10px;
            margin-top: 5px;
            padding: 0 18px;
        }

        &--actions-header {
            flex: 0 0 80px;
            font-size: 10px;
        }

        &--checkbox {
            flex: 0 0 20px;
        }

        &--line-height {
            @extend %font-bold;
            line-height: 25px;
            font-size: 10px;
        }
    }

    &__h-line {
        height: 14px;
        margin: auto 17px auto 0;
        width: 2px;
        background-color: $bg-investors-table;
    }

    &__separate-17 {
        margin-right: 17px;
    }

    &__main-p {
        padding: 0 18px;

        &--top {
            padding: 5px 18px 0 18px;
        }
    }

    &__arrow {
        transform: rotate(0deg);
        transition: all 0.5s ease-in-out;

        &--down {
            transform: rotate(180deg);
        }
    }

    &__paggination-arrow--container {
        height: 48px;
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        &:hover {
            background-color: rgba(1, 1, 1, 0.029);
        }
    }

    &__addition-table {
        padding-left: 30px;
    }

    &__pagginatio {
        height: 52px;
    }

    &__paggination-main {
        max-width: 330px;
        padding: fo;
    }

    &__paggination-main-text {
        font-size: 10px;
    }

    &__paggination-arrow {
        height: 24px;
        width: 24px;
    }
}

.company-info-modal-wrap {
    overflow: hidden;
}

.company-info-modal {
    .company-info-modal-title.wizard-heading:after {
        width: 0;
        height: 0;
    }

    .ant-modal-content {
        overflow: auto;
        height: 95vh;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.company-add-investor-form {
    input[disabled] {
        cursor: not-allowed;
    }
}

.company-info-container {
    margin-left: 8rem;
    margin-top: 30px;
}

.company-info {
    &:not(:first-child) {
        margin-top: 15px;
    }

    .label {
        flex: 0 0 30%;
    }

    .users-dropdown, .project-team-dropdown {
        border: 1px solid lightgray;
        width: 10rem;

        .ant-select-selector {
            border: none;
            background-color: unset !important;
        }
    }
}