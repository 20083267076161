.about {
    background-image: url('../../images/homa-page-second.png');
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    // height: 100vh;
    padding-bottom: 400px;

    &--bigger {
        background-image: url('../../images/homa-page-fist.png');
    }

    &__main {
        padding: 83px 30px;
        background-color: #fff;
        @include respond-to(485) {
            padding: 83px 90px;
        }
    }

    &-right {
        width: 40%;
    }

    &-left {
        width: 60%;

        &__title {
            @include respond-to(738) {
                height: 104px;
                width: 471px;
            }
        }

        &__title > p {
            @extend %font-light;
            color: $font-color-secondary;
            font-family: $font-family-1;
            font-size: 40px;
            letter-spacing: 0.84px;
            line-height: 52px;
        }

        &__text {
            color: $font-color-primary;
            font-family: $font-family-2;
            padding-top: 55px;
            height: 365px;
            width: 644px;
            letter-spacing: 0.2px;
            line-height: 28px;

            &-first {
                display: block;
                margin-bottom: 20px;
            }

            &-second {
                display: block;
                margin-bottom: 20px;
            }
        }
    }

    &-right {
        width: 40%;
    }

    &-right > img {
        height: 203px;
        width: 295px;
    }

    &__paragraph {
        max-width: 732px;
    }

    &__text-center {
        text-align: center;
    }

    &__heading {
        @extend %font-light;
        color: $font-color-secondary;
        font-size: 40px;
        padding-bottom: 10px;
        margin-bottom: 15px;
        letter-spacing: 0.8px;
        text-align: center;
    }

    &__cards-container {
        padding: 80px 0;
        gap: 80px;
        border-top: 1px solid $border-color-card;
        flex-direction: column;

        @include respond-to(738) {
            flex-direction: row;
        }
    }

    &__card {
        box-sizing: border-box;
        padding: 60px;
        flex: 1 1 50%;
        box-shadow: 0 0 20px 0 #b6dad54d;
        border-radius: 7px;
        background-color: transparent;
        transition: flex 2s, background-color 2s;

        &:hover {
            background-color: $bg-color-primary;
            flex: 1 1 60%;
        }
    }

    &__btn-width {
        width: 100%;

        @include respond-to(738) {
            width: 167px;
        }
    }
}

.btn-left {
    @extend %font-medium;
    color: $font-color-button-primary;
    font-family: $font-family-1;
    background-color: $bg-color-button-primary;
    border: none;
    height: 50px;
    width: 167px;
    border-radius: 4px;
    margin-top: 50px;
}
