.select-single-asset-page {
    padding-top: 80px;
    padding-left: 50px;

    &__main {
        gap: 20px;
    }

    &__single-card {
        flex: 0 0 calc(33% - 20px);
    }

    &__btn-positon {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
    }
}
