// General
@import 'general/fonts';
@import 'general/default-extensions';
@import 'general/variable.module';
@import 'general/grid-system';
@import 'general/general';
@import 'general/pagination';
@import 'general/size-down';
@import 'general/ck-editor';

// Libraries
@import '~antd/dist/antd.css';
@import 'react-toastify/dist/ReactToastify.css';
@import '~cropperjs/dist/cropper.css';

// Partials
@import 'partials/header';
@import 'partials/footer';
@import 'partials/heading';
@import 'partials/aboutus';
@import 'partials/hotels';
@import 'partials/network';
@import 'partials/explore';
@import 'partials/video';
@import 'partials/faq';
@import 'partials/property-bid';
@import 'partials/team-member-options';
@import 'partials/home-transaction';
@import 'partials/navigation';
@import 'partials/slide-in';
@import 'partials/home-page';
@import 'partials/dlt-header-page';
@import 'partials/_dlt-data-link';
@import 'partials/dlt-transaction';
@import 'partials/marketYieldChartDiv';
@import 'partials/chart-table';
@import 'partials/investor-table';
@import 'partials/property-hero';

// Pages
@import 'pages/add-property';
@import 'pages/choose-layout';
@import 'pages/property';
@import 'pages/cash-flow';
@import 'pages/project-team';
@import 'pages/debt-providers';
@import 'pages/research';
@import 'pages/listing-type';
@import 'pages/marketing-timeline';
@import 'pages/biding-structure';
@import 'pages/overview';
@import 'pages/marketing';
@import 'pages/transactions';
@import 'pages/approval';
@import 'pages/venture-companies-page';
@import 'pages/company-details';
@import 'pages/select-opportunity-page';
@import 'pages/bid-info-page';
@import 'pages/finance-details-page';
@import 'pages/bid-overview-page';
@import 'pages/schedule';
@import 'pages/newsletter-builder';
@import 'pages/search';
@import 'pages/portfolio';
@import 'pages/create-edit-template';
@import 'pages/stacking-plan-chart';
@import 'pages/data-room';
@import 'pages/overview-side-menu';
@import 'pages/data-room';
@import 'pages/pricing';
@import 'pages/static';
@import 'pages/dealine';
@import 'pages/select-single-asset-page';
@import 'pages/dashboard';
@import 'pages/invitations-side-menu';
@import 'pages/data-link-transaction-page';
@import 'pages/explore-data-page';
@import 'pages/theme-manager-page';
@import 'pages/data-analytic';
@import 'pages/base-wizard-layout';
@import 'pages/account-settings';
@import 'pages/building-directory';
@import 'pages/transaction-data';

// Shared
@import 'shared/building-card';
@import 'shared/calendar';
@import 'shared/property-card';
@import 'shared/tabs';
@import 'shared/transpose-table';
@import 'shared/select';
@import 'shared/radio';
@import 'shared/worker-card';
@import 'shared/wizard-with-sidebar';
@import 'shared/ckeditor';
@import 'shared/modal';
@import 'shared/markdown';
@import 'shared/ant-table';
@import 'shared/deadline';
@import 'shared/statistic';
@import 'shared/signNDAModal';
@import 'shared/property-modal';
@import 'shared/gallery-template';
@import 'shared/view-opportunity';
@import 'shared/cf-modal-newsletter';
@import 'shared/not-found-and-unauthorized';
@import 'shared/idle-timer';
@import 'shared/empty-section';

// @import 'shared/cropper';
.ant-tabs-left {
    .ant-tabs-nav {
        display: none !important;
        @include respond-to(768) {
            display: block !important;
        }
    }
}
