.cf {
    &-add {
        margin-bottom: 50px;

        & > p {
            margin-right: 10px;
        }
    }

    &-modal {
        @extend %relative;
        height: 660px;
        width: 667px;
        &__buttons {
            width: fit-content;
        }
    }

    &-modal-newsletter {
        @extend %relative;
        height: 660px;
        width: 300px;
        &__buttons {
            width: fit-content;
        }
    }

    &-modal .ant-modal-content {
        width: 676px;
        padding: 20px 46px;
    }

    &-modal textarea {
        height: 120px;
        resize: none;
        margin-bottom: 40px;
    }

    &-modal .ant-modal-body {
        padding: 0;
    }

    &-plus {
        background-color: $font-color-button-secondary;
        color: $font-color-light;
        height: 30px;
        width: 30px;
        line-height: 26px;
        border-radius: 30px;
        text-align: center;
        font-size: 2em;
        margin-left: 16px;
        cursor: pointer;
    }

    &-minus {
        background-color: $font-color-button-secondary;
        color: $font-color-light;
        height: 15px;
        width: 15px;
        line-height: 13px;
        border-radius: 30px;
        text-align: center;
        font-size: 2em;
        margin-left: 16px;
        cursor: pointer;
    }

    &-close {
        @extend %absolute;
        background-color: $font-color-light;
        color: $font-close-button;
        height: 30px;
        width: 30px;
        top: 10px;
        right: 10px;
        line-height: 25px;
        border-radius: 30px;
        text-align: center;
        font-size: 2em;
        transform: rotate(45deg);
        cursor: pointer;
    }
}

.teams-table {
    &__main {
        border-bottom: 2px solid $border-color-card;
        flex-wrap: wrap;
        gap: 30px;
    }

    &__group {
        width: 170px;
    }

    &__title {
        @extend %font-medium;
        font-size: 12px;
        color: $font-color-primary;
        text-transform: uppercase;
        padding: 15px 0;
        border-bottom: 1px solid $border-color-card;
    }

    &__info {
        font-size: 14px;
        padding: 15px 0;
        color: $font-color-bid-modal-info;
    }
}

.bidding {
    width: fit-content;
    padding: 3px 20px;
    font-size: 14px;
    border-radius: 4px;

    &__reject {
        background-color: $font-color-button-secondary;
        border: 1px solid $font-color-sun;
        color: $font-color-sun;
    }

    &__approved {
        background-color: $bg-underline-op50;
        border: 1px solid $border-color-radio-selected;
        color: $border-color-radio-selected;
    }

    &__placed {
        background-color: transparent;
        border: 1px solid $font-color-primary;
        color: $font-color-primary;
        height: 25px;
        line-height: normal;
    }

    &__pending {
        background-color: $font-color-status-panding-op;
        border: 1px solid $font-color-status-panding;
        color: $font-color-status-panding;
    }
}
