.finance-details-page {
  padding-bottom: 100px;

  &__form {
    gap: 80px;
  }

  &__group {
    &-sm {
      gap: 100px;
    }
  }
}
