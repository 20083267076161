.root {
  margin-top: 32px;
  margin-left: 66px;
  margin-right: 80px;
  overflow: auto;
  text-align: center;
}

.categories {
  margin: 0 auto;
  display: inline-flex;
  box-sizing: border-box;
}

.category {
  text-align: center;
  & + .category {
    margin-left: 4px;
  }
}

.name {
  display: block;
  padding: 8px 16px;
  color: #fff;
  font-size: 20px;
  line-height: inherit;
  white-space: nowrap;
}

.area {
  display: block;
  margin-top: 4px;
  font-size: 16px;
  line-height: inherit;
}

.percentage {
  display: block;
  font-size: 16px;
  line-height: inherit;
}
