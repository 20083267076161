.noteList {
    .divider {
        margin-left: 50px;
    }
}

.noteContentContainer {
    width: 100%;

    .noteContentHeader {
        display: flex;
        justify-content: space-between;
    }
}

.note {
    display: flex;
    flex-direction: row;
    gap: 20px;
    min-height: 70px;

    .userName {
        font-size: 16px;
    }

    .noteContent {
        white-space: pre-wrap;
        font-size: 14px;
    }
}
