.theme-manager-page {
    padding-top: 80px;
    padding-left: 50px;

    &__palette {
        margin-top: 30px;
        font-size: 18px;
    }

    &__color-picker {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 50;
    }

    &__color-preview {
        height: 30px;
        min-width: 20px;
        margin-top: 30px;
    }

    &__pr-31 {
        padding-right: 31px;
    }
}
