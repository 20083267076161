.slide-in {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 999;
    transform: translateX(-110%);
    transition: transform 2s, background-color 10s;

    @include respond-to(1024) {
        transition: transform 1s, background-color 10s;
    }

    &--show {
        transform: translateX(0%);
    }

    &__text {
        color: $slide-in-text-color;
        font-weight: 500;
    }
    &__icon {
        color: $slide-in-text-color;
    }

    &__main {
        background-color: transparent;
        transition-duration: 0.6s;
        transition-timing-function: ease-in-out;
        transition-delay: 1.4s;
        width: 100%;

        @include respond-to(1024) {
            transition-duration: 0.3s;
            transition-delay: 1s;
        }

        &--bg {
            background-color: rgba(15, 36, 47, 0.7);
        }

        &--tr {
            transition-delay: 0s;
            transition-duration: 0s;
            background-color: transparent;
        }
    }

    &__container {
        height: 100vh;
        width: 385px;
        background-color: $slide-in-bg-color;
        border-right: $slide-in-bg-color;

        .ant-menu-item-selected {
            background-color: transparent !important;

            &::after {
                left: 0;
                right: auto;
                border-right: 3px solid $font-color-secondary;
            }
        }

        li {
            height: 55px;
            color: $bg-color-active-tab;
            font-family: $font-family-secondary;
            display: flex;
            align-items: center;
        }
    }

    &__welcome {
        margin: 75px 0 40px 30px;
        font-size: 15px;
        font-family: $font-family-primary;
        color: $slide-in-text-color;
    }

    &__overview-logo {
        height: 14px;
        width: 14px;
    }

    &__adminPanel-logo {
        height: 14px;
        width: 14px;
    }
}

.property-menu.ant-menu {
    .slide-in__text {
        color: black;
    }
}
