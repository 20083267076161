.explore-data-page {
    &__main {
        flex: 1;
        .ant-tabs-nav div {
            padding-right: 5px;
        }
    }

    .ant-tabs-nav {
        margin-bottom: 0;
        margin-left: 30px;
        margin-right: 30px;
    }
}
