.MuiDataGrid-columnHeaderTitle {
    font-size: 10px;
    line-height: 31px !important;
    font-weight: 800 !important;
}

.MuiDataGrid-columnsContainer {
    min-height: 32px !important;
    max-height: 32px !important;
}

.MuiDataGrid-root .MuiDataGrid-window {
    top: 31px !important;
    overflow: hidden !important;
}

.MuiDataGrid-footerContainer {
    font-size: 10px;
    .MuiTablePagination-toolbar {
        font-size: 10px;
    }
    .MuiTypography-body2 {
        font-size: 10px;
    }
}

.MuiDataGrid-row {
    min-height: 32px !important;
    max-height: 32px !important;
    & > div {
        min-height: 32px !important;
        max-height: 32px !important;
        line-height: 31px !important;
    }
}
.MuiDataGrid-cell {
    font-size: 10px;
}

.ant-upload.ant-upload-drag p.ant-upload-text {
    font-size: 12px;
}

.ant-upload.ant-upload-drag p.ant-upload-hint {
    font-size: 10px;
}

.ant-select-item {
    font-size: 10px;
}
.MuiDataGrid-root .MuiDataGrid-cell {
    padding: 0 18px !important;
}
.ant-form label {
    font-size: 10px;
}
