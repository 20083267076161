.widgetValue {
    font-size: 25px;
    color: white;
}

.widgetLabel {
    font-size: 12px;
    color: white;
}

.circle {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    padding-top: 10px;
    background: #1c404f;
    border: 3px solid #1c404f;
    color: #1c404f;
    text-align: center;
}

.analysisStats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}