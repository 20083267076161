.cash-flow {
    &__title {
        font-family: $font-family-1;
        color: $font-color-primary;
        font-size: 14px;
    }

    &__radio {
        width: 450px;

        &-button > label {
            @extend %font-medium;
            font-family: $font-family-2;
            color: $font-color-primary;
            text-transform: none;
            letter-spacing: 0;
            margin-bottom: 0;
            font-size: 10px;
        }

        &-button > input {
            margin-right: 10px;
        }
    }
    @at-root &__table {
        position: relative;

        .ant-form-item {
            margin: 0;
            @extend %relative;
        }

        @at-root &-action {
            border: none;
            background-color: transparent;
            font-size: 10px;
            margin: 0 5px;
            cursor: pointer;
        }

        .ant-table-wrapper {
            .ant-spin-nested-loading {
                .ant-spin-container {
                    .ant-table {
                        .ant-table-container {
                            .ant-table-content {
                                table {
                                    tr {
                                        th {
                                            color: $font-color-primary;
                                            font-family: $font-family-2;
                                            font-size: 10px;
                                            font-weight: 800;
                                            letter-spacing: 0;
                                            line-height: 31px;
                                            text-align: left;
                                            color: rgba(0, 0, 0, 0.85);
                                        }

                                        td {
                                            font-size: 10px;
                                            font-family: $font-family-2;
                                            color: rgba(0, 0, 0, 0.85);
                                            font-weight: normal;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__top-container {
        // flex-direction: row;
    }

    &__buttons {
        @extend %absolute;
        left: 0;
        right: 0;
        padding: 10px 20px;

        &__add-record {
            float: right;
        }
    }

    &__buttons-main {
        position: absolute;
        right: 0;
        top: -70px;
    }

    &__form-info {
        font-family: $font-family-primary;
        font-size: 20px;
        color: $font-color-primary;
    }

    &__table-tr {
        color: $font-color-primary;
        font-family: Rubik;
        font-size: 10px;
        letter-spacing: 0;
        line-height: 17px;
    }

    &__information-table {
        .ant-spin-nested-loading {
            .ant-spin-container {
                .ant-table {
                    .ant-table-container {
                        .ant-table-content {
                            overflow-y: scroll !important;
                        }
                    }
                }
            }
        }
    }

    &__manage-btns {
        display: none;

        @include respond-to(920) {
            display: flex;
        }
    }

    &__manage-btns-tree-dots {
        display: flex;

        @include respond-to(920) {
            display: none;
        }
    }
}

.upload-spinner .ant-upload-list-text-container {
    display: none;
}

.upload-spinner .ant-btn-loading {
    color: $font-color-button-secondary;
}

.upload-spinner .ant-btn:hover {
    color: $font-color-light;
    border: none;
}

.upload-spinner .ant-btn:focus {
    color: $bg-color-button-primary;
    border: 1px solid $border-color-button-primary;
}

.upload-spinner .ant-btn:hover {
    color: $font-color-light;
    border: 1px solid $border-color-button-primary;
}
.ant-picker {
    padding-left: 5px;
}
