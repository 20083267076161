.root {
  width: 100%;
  min-height: 72px;
  display: flex;
  font-size: inherit;
  line-height: inherit;
  overflow: hidden;
  box-sizing: border-box;
}

.index {
  flex: none;
  padding: 8px 4px;
  min-height: inherit;
  display: flex;
  align-items: center;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.floor {
  width: 50px;
  font-size: 21px;
  justify-content: flex-end;
}

.area {
  width: 5em;
    font-size: 21px;
}

.rooms {
  display: flex;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.room {
  font-size: inherit;
  line-height: inherit;
  padding: 10px 4px;
  min-width: 25px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  font-size: 16px;

  & + .room {
    margin-left: 2px;
  }
}

.truncateText {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;

  & + .truncateText {
    margin-top: 4px;
  }
}
