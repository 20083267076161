.view-opportunity {
    height: auto;
    margin-right: 200px;

    .ant-modal-content {
        width: 800px;
    }

    &__content {
        width: 600px;
        overflow: hidden;
        overflow-x: scroll;
    }
}
