.dlt-data-link {
    padding-top: 100px;
    &__main {
        flex: 0 0 50%;

        &--pr-50 {
            padding-right: 80px;
        }
    }

    &__secondary {
        flex: 0 0 50%;
        &--grey {
            padding: 80px 50px;
            background-color: $bg-dlt-cards;
        }
    }

    &__title {
        @extend %font-bold;
        font-size: 32px;
        color: $font-home-page-about-heading;

        &--light-grey {
            color: $bg-dlt-transactions-light-grey;
        }
    }

    &__subtitle {
        font-size: 18px;
        color: $bg-dlt-header;

        &--light-grey {
            color: $bg-dlt-transactions-light-grey;
        }
    }

    &__cards {
        background-color: $bg-dlt-cards;
        gap: 50;
        padding: 100px 50px;
        display: flex;

        &--no-bg {
            background-color: transparent;
            overflow-x: scroll;
        }

        &--grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 50px;
        }
    }

    &__single-card-title {
        @extend %font-bold;
        font-size: 18px;
        color: $bg-color-button-secondary;
    }

    &__single-card-subtitle {
        font-size: 14px;
        color: $bg-color-button-secondary;
    }

    &__single-card {
        padding: 0 20px;
        flex: 33%;

        &--fixed {
            transform: translateX(-50px);
            opacity: 0;
            transition: 0.5s all ease-in-out;

            &--show {
                opacity: 1;
                transform: translateX(0px);
            }
        }

        &--animate {
            transform: translateY(50px);
            opacity: 0;
            transition: 0.5s all ease-in-out;
            &--show {
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }

    &__single-card-icon {
        color: $bg-color-button-secondary;
        font-size: 64px;
    }

    &__active-btn {
        color: $font-home-page-about-heading;
        border: 1px solid $font-home-page-about-heading;
        background-color: transparent;
        padding: 5px 10px;

        &--not {
            color: $font-color-sun;
            border: 1px solid $font-color-sun;
        }
    }
}
