.network {
    @extend %relative;
    height: 403px;
    padding-left: 90px;

    & > .heading {
        margin-top: 80px;
    }
    &-icons {
        @extend %absolute;
        left: 124px;
        width: 83%;
        top: 260px;
        z-index: 10;

        & > img {
            @extend %absolute;
            width: 100px;
            left: 33px;
            top: -175px;
            height: 281px;
            width: 1123px;
            z-index: -1;
        }
        &__icon > img {
            height: 72px;
            width: 72px;
        }

        &__icon {
            display: flex;
            transition: transform 0.7s ease-in-out;
            &:hover {
                transform: scale(1.2);
            }
        }

        &__right {
            margin-left: 20px;
        }
    }

    &-bg {
        color: $bg-underline-op;
        font-family: $font-family-1;
        @extend %absolute;
        left: 283px;
        top: 70px;
        opacity: 0.2;
        font-size: 250px;
        font-weight: 800;
        letter-spacing: 8px;
        line-height: 325px;
        z-index: 0;
    }
}
