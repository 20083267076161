.property-bid {
    @at-root &__wrapp {
        @extend %absolute;
        padding: 20px 0;
        bottom: -60px;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 0 0 20px 0 rgba(182, 218, 213, 0.25);
        border-radius: 7px;

        @include respond-to(720) {
            display: block;
            flex-direction: row;
        }
    }

    @at-root &__main-container {
        box-shadow: 0 0 20px 0 rgba(182, 218, 213, 0.25);
        border-radius: 7px;
        font-family: $font-family-secondary;
        width: 100%;
        height: auto;
        flex-direction: column;
        gap: 20px;
        padding: 20px 0;

        @include respond-to(720) {
            height: 120px;
            gap: 0;
            flex-direction: row;
            padding: 0;
        }
    }

    @at-root &__main-info {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding-right: 30px;

        h5 {
            @extend %font-light;
            color: $font-color-secondary;
            font-size: 25px;
        }
        p {
            font-size: 13px;
            color: $font-color-label;
            letter-spacing: 1.21px;
            text-transform: uppercase;
        }

        &--red {
            background-color: rgba(253, 98, 90, 0.316);
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            padding: 20px;
        }

        &--radius {
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            padding-left: 20px;
        }

        @include respond-to(720) {
            border-right: 1px solid $border-color-card;
        }
    }

    @at-root &__info {
        flex-direction: column;

        h5 {
            @extend %font-medium;
            color: $font-color-primary;
            font-size: 14px;
        }

        p {
            @extend %font-regular;
            font-size: 14px;
            color: $font-color-label;
        }

        @include respond-to(720) {
            flex-direction: row;
            margin-left: 30px;
        }

        &--natValue {
            @include respond-to(720) {
                display: none;
            }
            @include respond-to(1100) {
                display: flex;
                flex-direction: column;
            }
        }
    }

    @at-root &__time {
        height: 48px;
        width: 182px;
        border: 1px dashed $border-color-button-primary;
        border-radius: 27.5px;
        margin-right: 30px;

        p {
            @extend %font-light;
            color: $font-color-label;
            font-size: 16px;
        }
    }

    @at-root &__buttons {
        .button-main {
            max-width: 128px;
        }
    }

    @at-root &__button-team {
        border: none;
        background-color: white;
        color: $font-color-button-secondary;
        font-size: 14px;
        margin-top: 10px;
        text-decoration: underline;
    }

    &__basic-info {
        flex-direction: column;
        margin-right: 0;
        gap: 20px;
        @include respond-to(720) {
            gap: 0;
            flex-direction: row;
            margin-left: 30px;
        }
    }

    &__second-part {
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;

        @include respond-to(720) {
            justify-content: baseline;
            flex-direction: row;
            gap: 0;
            margin-top: 0;
        }
    }

    &__burger {
        gap: 5px;
        margin: 0 auto;
        cursor: pointer;
        width: fit-content;

        @include respond-to(720) {
            display: none;
        }
    }

    &__burger-line {
        height: 2px;
        width: 40px;
        background: $bg-color-active-tab;
    }

    &__view-toggle {
        display: flex;
        height: 100%;
        flex-direction: column;
        margin-top: 20px;
        justify-content: baseline;
        width: 100%;
        // background-color: white;

        &--none {
            display: none;
            @include respond-to(720) {
                display: flex;
            }
        }

        @include respond-to(720) {
            justify-content: space-between;
            margin-top: 0;
            flex-direction: row;
        }
    }
}
