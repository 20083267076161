.markdown {
    @at-root &__wrap {
        div {
            display: block;    
        }

            @media screen and (max-width: 768px) {
                span {
                    display: flex;
                    flex-direction: column;
                    padding: 0px 20px;

                    figure {
                        width: 100%;
                    }
                }

                h2,h3,h4 {
                    text-align: center;
                }

                p {
                    text-align: justify;
                }
           
            }

        h2 {
            font-size: 30px;
        }

        h3 {
            font-size: 21px;
        }

        h4 {
            font-size: 21px;
        }
    }

    &__fon-pading {
        padding: 0 20px;

        @include respond-to(738) {
            padding: 0;
        }
    }
}

.ck-editor__main {
    h2 {
        font-size: 22px;
    }

    h3 {
        font-size: 19px;
    }

    h4 {
        font-size: 12px;
    }
}
