.overview-side-menu {
    padding-top: 80px;

    &__infomations {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 65px;
        padding: 0 20px;

        @include respond-to(568) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include respond-to(768) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include respond-to(1024) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &__tabs {
        .ant-tabs-nav {
            padding-left: 0 !important;
        }
    }

    &__main-container {
        max-width: 1120px;
        margin: 0 auto;
    }

    &__view-type {
        cursor: pointer;
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: transparent;
        }

        &--selected {
            &::after {
                background-color: $border-color-dashed;
            }
        }
    }

    @include respond-to(1024) {
        padding-left: 50px;
        padding-right: 50px;
    }
}

.overview-page-card {
    padding: 15px;
    box-shadow: 0 0 20px 0 $bg-overview-card-shadow;
    cursor: pointer;

    &__logo {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: $border-color-footer;
        color: $font-color-side-menu-number;
    }
    color: #8b93a2;

    &__main-info {
        @extend %font-medium;
        font-family: $font-family-primary;
        font-size: 10px;
        color: $font-color-side-menu-number;
    }

    &__number {
        @extend %font-light;
        font-size: 30px;
        font-family: $font-family-primary;
        color: $font-color-side-menu-number;
    }

    &__sub-info {
        @extend %font-regular;
        font-family: $font-family-secondary;
        font-size: 8px;
        color: $font-color-tabs;
    }

    &--selected {
        box-shadow: 0 0 20px 0 $bg-overview-card-selected-shadow;

        .overview-page-card__logo {
            background-color: $bg-overview-card-logo-light;
            color: $bg-color-button-primary;
        }

        .overview-page-card__number {
            font-family: $font-family-primary;
            color: $bg-color-button-primary;
        }
    }
}

.overview-side-menu a {
    font-size: 10px;
    text-decoration: underline;
}

.overview-property-spinner {
    position: relative;
    top: 50%;
    left: 50%;
    color: $font-color-button-secondary;
}

.overview-add-property-button {
    padding: 0 0;
    display: flex;
    justify-content: end;
}
