.building-directory {
  .content-text {
    padding-top: 0 !important;
  }

  .heading {
    color: $main-bg-color;
    font-size: 26px;
    font-weight: 400;
  }

  .heading::after {
    width: 4rem;
    height: 4px;
    background-color: #ebf4f2;
    opacity: 1;
  }

  .searching-map {
    .background-cover {
      background-color: $main-bg-color;

      .search-content {
        width: calc(100% - 50px);
        display: inline-flex;
        flex-direction: row;
        align-items: stretch;
        background-color: #fafafa;
      }
    }

    .search-panel {
      width: 34rem;
      vertical-align: top;
      display: inline-block;
      overflow-x: hidden;
    }

    .map-panel {
      display: inline-block;
      background-color: white;
      width: calc(100% - 34rem);
    }

    .search-name {
      margin: 1rem 1rem 0 2rem;
      color: $main-bg-color;
      font-size: 24px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .filter-collapse {
      border-top: 1px solid #d9d9d9;

      .average-price {
        margin-top: 8px;
      }

      .price-chart {
        height: 200px;
        width: calc(100% + 22px);
        margin-top: -50px;
      }
    }

    .search-address {
      color: #888888;
      font-weight: 500;
      margin-left: 2rem;
      margin-bottom: 1rem;
      margin-right: 1rem;
    }

    .ant-collapse-content-box {
      padding-left: 2rem;
      padding-right: 0;
    }

    .ant-collapse-header {
      border-bottom: 1px solid #d9d9d9;
      margin-left: 2rem;
      margin-right: 1rem;
      padding-left: 0;
      font-size: 1rem;
      color: $main-bg-color;
      font-weight: 500;
    }

    .ant-collapse-item:last-child {
      border-bottom: none;
    }

    .ant-checkbox-wrapper {
      text-transform: initial;
      font-family: $font-family-primary;
      color: $main-bg-color;
      font-size: 12px;
      font-weight: 500;
      width: calc(33.33% - 1.2rem);
      border-bottom-width: 3px;
      border-bottom-style: solid;
      margin-top: 1.8rem;
      margin-right: 1.2rem;
      padding-bottom: 1rem;

      &:nth-child(1) {
        border-bottom-color: #fade5f;
      }

      &:nth-child(2) {
        border-bottom-color: #acc451;
      }

      &:nth-child(3) {
        border-bottom-color: #419dcb;
      }

      &:nth-child(4) {
        border-bottom-color: #955d99;
      }

      &:nth-child(5) {
        border-bottom-color: #f0963e;
      }

      &:nth-child(6) {
        border-bottom-color: #f1592a;
      }

      &:nth-child(7) {
        border-bottom-color: #539777;
      }
    }

    .checkbox-group {
      margin-top: -0.5rem;
      width: 100%;

      &.sector .ant-checkbox-wrapper {
        width: calc(25% - 1.2rem);
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $main-bg-color;
      border-color: $main-bg-color;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus+.ant-checkbox-inner {
      border-color: $main-bg-color;
    }

    .ant-slider-track {
      background-color: $main-bg-color;
    }

    .ant-slider-handle {
      width: 20px;
      height: 20px;
      margin-top: -8px;
    }

    .ant-slider-handle-1 {
      background-color: #f1592a;
      border-color: #f1592a;
    }

    .ant-slider-handle-2 {
      background-color: #f7941d;
      border-color: #f7941d;
    }

    .price-slider {
      margin-left: 0.6rem;
      margin-right: 1.7rem;
    }

    .price-title {
      margin-top: 1.7rem;
      font-size: 14px;
      font-weight: 500;
      color: $main-bg-color;
    }

    .price-value {
      font-weight: 600;
      font-size: 12px;
      margin-right: 0.8rem;

      .max-price {
        float: right;
        margin-right: 0.4rem;
      }
    }
  }

  .info-photo {
    display: inline-block;
    width: 33.33%;
    padding: 0.25rem 0.5rem;
  }

  .gallery {
    display: block !important;
  }

  .navigation-icon {
    display: inline-block;
    vertical-align: top;
    width: 50px;

    >hr {
      width: 35px;
    }

    >img {
      width: 50px;
      height: 50px;
    }

    >img:last-child {
      position: absolute;
      left: 0;
    }
  }

  .search-menu {
    padding: 30px;
    position: relative;
    overflow-y: auto;
    height: calc(100vh - 60px);

    .search-title {
      color: $main-bg-color;
      font-weight: 600;
      font-size: 18px;
    }

    .search-description {
      font-size: 14px;
    }

    >hr {
      margin-top: 10px;
      width: 7rem;
      height: 4px;
      background-color: $main-bg-color;
      float: left;
    }

    .bar {
      position: relative;
      margin: 0 auto;
      margin-top: 40px;
      width: 100%;
      border-radius: 30px;
      border: 1px solid #dcdcdc;
    }

    .bar:hover {
      box-shadow: 1px 1px 8px 1px #dcdcdc;
    }

    .bar:focus-within {
      box-shadow: 1px 1px 8px 1px #dcdcdc;
      outline: none;
    }

    .searchbar {
      height: 40px;
      border: none;
      width: calc(100% - 150px);
      margin-left: 110px;
      font-size: 14px;
      outline: none;

      &::placeholder {
        font-size: 14px;
      }
    }

    .country-select {
      position: absolute;
      top: -1px;
      left: 0;
      width: 116px;

      &.ant-select:hover {
        border-color: transparent;
      }

      .ant-select-selector {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }

      .ant-select-selection-placeholder,
      .ant-select-selector {
        font-size: 12px;
      }

      .ant-select-selection-search-input {
        margin-top: -2px;
      }
    }

    .searchicon {
      width: 20px;
      top: 10px;
      right: 14px;
      position: absolute;
      cursor: pointer;
    }

    .search-result {
      margin: 20px -20px;
      margin-right: -30px;

      >div.building-card {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 10px;
        width: calc(50% - 10px);

        &:nth-child(even) {
          margin-left: 5px;
        }

        &:nth-child(odd) {
          margin-right: 5px;
        }
      }
    }

    .tip-box {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
      padding-bottom: 30px;
      background-color: #f3f3f4;
      border-top: 1px solid #b2b3b9;

      .close-icon {
        text-align: right;
        cursor: pointer;
      }

      .image {
        width: 50px;
        display: inline-block;
        margin-top: 20px;
        vertical-align: top;
      }

      .text {
        width: calc(100% - 50px);
        display: inline-block;
        font-weight: 500;
        padding: 0 20px;
      }
    }
  }

  .trade-probability-title {
    font-size: 20px;
    color: $main-bg-color;
    border-bottom: 1px solid $main-bg-color;
    margin-left: 16px;
    margin-right: 25px;
    margin-top: 25px;
  }

  .building-card {
    padding: 20px 10px;
    border: 5px solid $main-bg-color;
    width: 300px;
    cursor: pointer;

    &>div {
      padding: 2px 10px;
      color: #707288;
    }

    .name {
      color: $main-bg-color;
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .address {
      font-size: 12px;
      font-weight: 700;
      padding-top: 0;
      white-space: initial;
    }

    .title {
      font-size: 14px;
      font-weight: 700;
    }

    .value {
      font-size: 13px;
      font-weight: 600;
      float: right;
    }

    .photo {
      margin: -20px -10px 8px -10px;
      height: 120px;
      width: calc(100% + 20px);
      object-fit: cover;
      object-position: center;
    }
  }

  .map-cluster-marker {
    width: 34px;
    height: 34px;
    border-radius: 17px;
    text-align: center;
    font-size: 14px;
    padding-top: 2px;
    border: 5px solid $main-bg-color;
    background: white;
  }

  .map-marker {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid #d3d3d3;
  }

  .subcontrol-container .tooltiptext {
    display: none !important;
  }
}

.region-select {
  width: calc(100% - 1rem);

  .ant-select-selector {
    border: 1px solid #dcdcdc !important;
  }

  .ant-select-selection-item {
    color: black;
    font-size: 14px;
  }
}

.searching-map {
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 35vh;
    width: calc(100vw - 1.2rem);
    height: calc(100vh - 60px);
    background-color: #04090ba3;
  }
}

.search-countries,
.search-cities,
.search-list {
  min-width: 200px;
  max-width: 200px;

  &.ant-select {
    border: solid 1px #1c4050f1;
  }
}

.search-date {
  max-width: 200px;
  
  &.ant-picker {
    border: solid 1px #1c4050f1;
  }
}