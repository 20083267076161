.dlt-header-page {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: $bg-dlt-header;
    &__title {
        font-size: 32px;
        color: $font-color-header-light;
    }
    &__subtitle {
        font-size: 16px;
        color: $font-color-header-light;
    }

    &__main {
        width: 50%;
    }
}
