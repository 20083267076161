.cf-modal-newsletter {
    &__select {
        background: #fdfefe;
        outline: none;
        border: 1px solid #eff3f9;
        border-radius: 5px;
        min-height: 40px;
        cursor: pointer;
        gap: 100px;
        padding: 0 10px;

        & p {
            color: #737373;
        }
    }

    &__rotate {
        transform: rotate(90deg);
    }

    &__dropdown-container {
        @extend %relative;
    }

    &__dropdown {
        @extend %absolute;
        top: 100%;
        overflow-y: scroll;
        max-height: 250px;
        background: #fff;
    }
}
