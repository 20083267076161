.wizard-container {
    @extend %relative;
    background-color: $bg-color-primary;
    border-left: transparent;
    overflow: hidden;

    &-steps {
        height: 90vh;
        overflow: scroll;
        padding: 100px 0 50px 0;
    }

    &:after {
        @extend %absolute;
        content: '';
        background-color: #dcefed;
        bottom: 0;
        height: 206px;
        width: 655px;
        z-index: 1;
        transform-origin: center;
        transform: translate(-20%, 44%) rotate(28deg);
    }

    &:before {
        @extend %absolute;
        content: '';
        background-color: #c0d5d4;
        bottom: 0;
        height: 206px;
        width: 655px;
        z-index: 2;
        transform-origin: center;
        transform: translate(-30%, 45%) rotate(36deg);
    }

    .ant-tabs-left {
        position: fixed;
        height: 100vh;
        width: 100%;
        .ant-tabs-nav {
            max-width: 260px;
            width: 50%;
            height: 100%;
            min-height: 100vh;
            padding-top: 130px;
            background-color: $wizard-bg-color;

            .ant-tabs-ink-bar {
                display: none;
            }

            .current-step {
                display: none;
            }

            .ant-tabs-tab {
                margin-bottom: 10px;
                .ant-tabs-tab-btn {
                    span {
                        @extend %font-regular;
                        font-size: 14px;
                        text-transform: uppercase;
                    }

                    p {
                        color: $wizard-text-color;
                    }
                }
                &.ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                        width: 100%;
                        color: $font-color-secondary;
                        p {
                            margin-left: 20px;
                        }
                        span {
                            @extend %font-medium;
                            font-size: 18px;

                            &::after {
                                content: '';
                                display: block;
                                padding-left: 20px;
                                height: 1px;
                                width: 100%;
                                background-color: $wizard-text-color;
                            }
                        }
                    }
                }
            }

            @include respond-to(900) {
                max-width: 310px;
            }
            @include respond-to(950) {
                max-width: 380px;
            }
            @include respond-to(1000) {
                max-width: 440px;
            }
        }
    }
    .ant-tabs-content-holder {
        @extend %relative;
        background-color: white;
        z-index: 3;
        overflow: auto;
        .button-wrap {
            @extend %absolute;
            background-color: white;
            bottom: 0;
            left: 20px;
            right: 20px;
            z-index: 999;
        }
    }
}

.wizard-main-wrap {
    margin-top: 80px;
    .transpose-table__wrap {
        margin-bottom: 100px;
    }
}

.wizard-form {
    margin-top: 50px;
    padding-bottom: 100px;
    .ant-select-selection-search-input {
        font-size: 10px;
    }
}

.subtab > p {
    text-transform: uppercase;
    font-family: $font-family-1;
    font-size: 18px;
    @extend %font-medium;
}

.subtab-name {
    margin-left: 59px;
}
