.newsletter-builder {
    @at-root &__image {
        @extend %relative;
    }

    &__def-image {
        display: block;
        max-height: 2400px;
        max-width: 1500px;
        margin: 0 auto;
    }

    @at-root &__upload {
        @extend %flex-absolute-centered-max-size;

        .ant-btn {
            height: 53px;
            width: 167px;
            border: 1px dashed $border-color-dashed;
            border-radius: 8px;

            p {
                text-decoration: underline;
            }
        }
    }

    &__boreder-red {
        border: 1px solid $bg-color-button-primary;
    }

    &__main-template-content {
        width: 80%;
        border: 1px solid $border-color-card;
        padding-bottom: 30px;
        border-radius: 8px;
    }
}
