.idle-timer {
    &__modal {
        background-color: $bg-idle;
        .ant-modal-content {
            background-color: transparent !important;
            box-shadow: none !important;
        }
    }

    &__icon {
        color: $font-color-header-light;
        font-size: 70px;
    }

    &__text-color {
        color: $font-color-header-light;
        &::after {
            display: none;
        }
    }
}
