.image-style-align-left, .image-style-align-right {
    width: 50%;
}

.image-style-align-left {
    float: left;
    margin-right: 15px;
}

.image-style-align-right {
    float: right;
    margin-left: 15px;

    @media screen and (max-width: 768px) {
        
        margin: 0px;
        float: none;
    }
}