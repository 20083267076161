.stacking-plan-chart {
    border-radius: 8px;
    background-color: white;
    margin-bottom: 24px;

    &__text {
        font-size: 10px !important;
        &--title {
            font-size: 11px !important;
        }
    }
}
