.explore {
    padding: 0 25px;

    @include respond-to(738) {
        padding-left: 90px;
    }
    .ant-tabs-nav-list {
        margin: auto;
        .ant-tabs-tab {
            margin: 0 50px;
        }
    }
}
