.propery-modal {
    width: 820px !important;
    height: 570px !important;

    .ant-modal-body {
        height: 570px !important;
    }

    &__address {
        font-size: 12px;
        font-family: $font-family-secondary;
        color: $border-color-dashed;
    }
}

.editCashFlowModal {
    width: 80% !important;
    background-color: $bg-color-button-secondary;
    .ant-modal-content {
        box-shadow: none !important;
    }
}
