footer {
    background-color: $bg-color-footer;
    color: $font-color-footer;
    font-size: 16px;

    h5 {
        @extend %font-medium;
        font-family: $font-family-primary;
        font-size: 18px;
        margin-bottom: 20px;
    }

    li {
        @extend %font-regular;
        margin-bottom: 5px;
    }
}

.footer {
    &-main-container {
        border-bottom: 1px solid $border-color-footer;
        font-family: $font-family-secondary;
        padding: 80px 65px 60px;
    }

    &-copyright-container {
        padding: 35px 25px;

        p {
            @extend %font-light;
            font-size: 14px;
        }
        a {
            font-size: 14px;
        }

        .footer-copyright-text {
            @extend %font-medium;
            margin: 0 20px;
        }
    }

    @at-root &-social-media {
        display: none;
        // margin-top: 40px;
        // a {
        //     margin-right: 20px;
        // }
    }

    &__href {
        display: block;
        width: fit-content;
        color: $border-color-footer !important;
        font-size: 14px;
        background-color: transparent;
        outline: none;
        border: none;

        &:hover {
            color: #fff !important;
            text-decoration: 1px underline #fff;
        }

        &--social {
            color: #fff !important;
            font-size: 25px !important;

            &:hover {
                color: $border-color-footer !important;
            }
        }
    }

    &__list-of-services {
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 200px;

        @include respond-to(783) {
            max-height: none;
            flex-direction: row;
        }

        &--secondary {
            flex-wrap: wrap;

            @include respond-to(738) {
                margin-top: 100px;
            }
        }

        & > li {
            margin-bottom: 20px;
            @include respond-to(738) {
                margin-bottom: 0;
            }
        }
    }

    &__social {
        margin-top: 120px;

        @include respond-to(738) {
            margin: 0;
        }

        p {
            font-size: 14px;
        }
        a {
            font-size: 14px;
        }
    }

    &__terms-list {
        display: none;

        @include respond-to(738) {
            display: flex;
        }
    }
}
