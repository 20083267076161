.bid-actions {
    height: 30px;
    width: 68px;
    cursor: pointer;

    &--sizeUp {
        width: 80px;
    }
}

.oppurtunity-updates {
    &__main-container {
        flex-wrap: wrap;
        row-gap: 20px;
    }

    @at-root &__card {
        height: 120px;
        border: 1px solid $border-color-card;
        border-radius: 7px;
        background-color: white;
        box-sizing: border-box;
        box-shadow: 0 0 20px 0 rgba(182, 218, 213, 0.3);
        padding: 5px 15px;
    }

    @at-root &__icon {
        @extend %relative;
        background-color: $bg-color-icon;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        padding-left: 5px;
        margin-right: 10px;
    }

    @at-root &__read {
        @extend %absolute;
        background-color: $bg-color-button-primary;
        border-radius: 50%;
        height: 5px;
        width: 5px;
        top: 3px;
        right: 2px;
    }

    @at-root &__title {
        @extend %font-bold;
        color: $font-color-primary;
        font-family: $font-family-secondary;
        font-size: 8px;
        letter-spacing: 1.13px;
        text-transform: uppercase;
    }

    @at-root &__text {
        @extend %font-regular;
        color: $font-color-label;
        font-family: $font-family-secondary;
        font-size: 10px;
        letter-spacing: 0;
        margin-top: 8px;
        margin-bottom: 6px;
    }

    @at-root &__date {
        color: $font-color-date;
        font-family: $font-family-secondary;
        font-size: 8px;
        letter-spacing: 0;

        &--margin-t-a {
            margin-top: auto;
        }
    }

    &__left-part {
        padding: 10px 0;
        height: 100%;
    }

    &__second-part {
        height: 100%;
    }
}
.comment-text-area {
    min-height: 20vh;
    width: 100%;
    display: block;
}

.comment-button {
    float: right;
}

.transaction {
    &__company-details {
        flex-wrap: wrap;
        gap: 30px;
        padding: 30px;
        flex-direction: column;
        // align-items: center;
        width: fit-content;
        margin: 0 auto;

        @include respond-to(640) {
            flex-direction: row;
            align-items: baseline;
            margin: 0;
            width: 100%;
        }
    }

    &__group {
        flex-direction: row;
        gap: 20px;
        align-items: center;
        @include respond-to(640) {
            align-items: baseline;
            gap: 0;
            flex-direction: column;
            flex: 0 0 calc(50% - 30px);
        }
    }

    &__title {
        @extend %font-medium;
        font-family: $font-family-secondary;
        font-size: 10px;
        color: $font-color-primary;
    }

    &__subtitle {
        @extend %font-regular;
        font-family: $font-family-secondary;
        font-size: 12px;
        color: $font-color-tabs;

        @include respond-to(640) {
            font-size: 10px;
        }
    }

    &__title-company {
        @extend %font-medium;
        font-family: $font-family-secondary;
        font-size: 10px;
        color: $font-color-primary;
    }

    &__subtitle-company {
        @extend %font-regular;
        font-family: $font-family-secondary;
        font-size: 12px;
        color: $font-color-tabs;

        @include respond-to(640) {
            font-size: 10px;
        }
    }

    &__info-about {
        @extend %font-bold;
        font-size: 12px;
        color: $font-color-primary;
        border-bottom: 1px solid $border-color-card;
        padding: 30px;

        &--border-none {
            border-bottom: none;
        }
    }

    &__company-details-container {
        border: 1px solid $border-color-card;
        border-radius: 6px;
    }

    &__list-container {
        padding: 30px 30px 30px 50px;
    }

    &__top-container {
        border-bottom: 1px solid $border-color-card;
    }

    &__list-item {
        @extend %font-regular;
        @extend %relative;
        font-size: 14px;
        font-family: $font-family-secondary;
        color: $font-color-bid-modal-info;
        word-break: break-all;

        &::before {
            @extend %absolute;
            content: '.';
            line-height: 10px;
            font-size: 18px;
            top: 2px;
            left: -20px;
        }

        &--small {
            font-size: 10px;
            padding-left: 50px;
            &::before {
                top: -2px;
                left: 20px;
            }
        }
    }
}
