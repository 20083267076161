.base-wizard-layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;

    &__left {
        height: 100vh;
        min-width: 335px;
        max-width: 355px;
        background-color: $main-bg-color;
        border-right: $main-bg-color;
        padding-top: 80px;
    }

    &__left-txt {
        color: $main-text-color;
        font-weight: 500;
        padding: 10px 0 10px 60px;
        cursor: pointer;

        &--active {
            padding-left: 20px;
            text-decoration: underline solid $main-text-color;
        }
    }

    &__right {
        width: 100%;
        padding: 80px 0 0 20px;
        overflow-y: scroll;
    }
}
