%clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}
//font setup
%font-light {
    font-weight: 300;
}

%font-regular {
    font-weight: 400;
}
%font-medium {
    font-weight: 500;
}

%font-bold {
    font-weight: 600;
}
//position setup
%relative {
    position: relative;
}
%absolute {
    position: absolute;
}
//favorite extends
%transition-favorite {
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}
%flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}
%flex-absolute-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
}
%absolute0000 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
}
%max-size {
    height: 100%;
    width: 100%;
}
%absolute-max-size {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
}
%flex-absolute-max-size {
    display: flex;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
}
%flex-absolute-centered-max-size {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
}
%flex-absolute0000 {
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
}
%margin-right-left-auto {
    margin-right: auto;
    margin-left: auto;
}
%background-image-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

%heading {
    @extend %relative;
    &:after {
        @extend %absolute;
        content: '';
        background-color: var(--main-bg-color);
        opacity: 0.3;
        height: 1px;
        width: calc(100% - 16px);
        bottom: 0;
        left: 0;
    }
}
%loading {
    &:after {
        display: none;
    }
}

%top-border-radius {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

@mixin respond-to($media) {
    @media screen and (min-width: $media + px) {
        @content;
    }
}
