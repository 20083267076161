.empty-section {
    .add-property-image-builder__upload {
        &--margin-x {
            margin: 0 auto;
        }
        position: relative;
        .ant-btn {
            margin-top: 0;
        }
    }

    &__content {
        width: 100%;

        .ant-btn {
            height: 350px;
            width: 100%;
            border: 1px dashed $border-color-dashed;
            border-radius: 8px;
            margin-top: 0;
        }
    }

    &__button {
        margin: 0 auto;
    }

    &__main-text {
        @extend %font-bold;
        font-size: 18px;
        color: $border-color-dashed;
    }

    &--small {
        .empty-section__content {
            width: 100%;

            .ant-btn {
                height: 150px;
            }
        }

        .empty-section__main-text {
            display: none;
        }
    }
}

.topic-empty-section,
.question-empty-section {
    .empty-section {
        &__content {
            .ant-btn {
                height: 150px;
            }
        }
    }
}
