.building-card {
    @at-root &__container {
        border-radius: 4px;
        width: 100%;
        max-width: 610px;
        box-shadow: 0 0 20px 0 rgba(182,218,213,0.2);
        margin: auto;
    }

    @at-root &__image {
        max-width: 230px;
        img {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }
    @at-root &__name {
        @extend %font-light;
        color: $font-color-secondary;
        font-size: 20px;
        margin-top: 15px;
    }

    @at-root &__address {
        @extend %font-regular;
        font-family: $font-family-secondary;
        font-size: 12px;
    }

    @at-root &__year {
        @extend %font-bold;
        color: $font-color-label;
        font-size: 14px;
        margin-top: 25px;
        margin-bottom: 5px;
    }

    @at-root &__element {
        @at-root &-list {
            border-top: 1px solid $border-color-card;
            padding-top: 15px;
        }
        @at-root &-item {
            border-right: 1px solid $border-color-card;
            padding: 0 15px;
            margin-bottom: 20px;
            text-align: center;
            &:last-of-type {
                border: none;
            }
        }

        @at-root &-name {
            @extend %font-medium;
            font-family: $font-family-secondary;
            font-size: 14px;
        }

        @at-root &-value {
            @extend %font-medium;
            color: $font-color-label;
            font-family: $font-family-secondary;
            font-size: 14px;
            margin-top: 10px;
        }
        
    }
}