.property-hero {
    &__timeline-dot {
        height: 15px;
        width: 15px;
        background-color: $main-text-color;
        opacity: 0.6;
        border: 1px solid $main-text-color;
        position: relative;
        border-radius: 50%;

        &--active {
            opacity: 1;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: transparent;
                border: 1px solid $main-text-color;
                border-radius: 50%;
                height: 15px;
                width: 15px;
                animation-name: resizeDot;
                animation-duration: 2s;
                animation-iteration-count: infinite;
            }
        }
        &--active-bg {
            opacity: 1;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: transparent;
                border: 1px solid $main-bg-color;
                border-radius: 50%;
                height: 15px;
                width: 15px;
                animation-name: resizeDot;
                animation-duration: 2s;
                animation-iteration-count: infinite;
            }
        }

        &--main-bg {
            background-color: $main-bg-color;
            border: 1px solid $main-bg-color;
        }
    }
    &__timeline-title {
        color: $main-text-color;
    }
    .ant-steps-item-tail {
        margin: 3px 0 0 6px;
    }
    .ant-steps-item-icon {
        margin-left: 0 !important;
    }
    .ant-steps-item-title {
        position: absolute !important;
        left: -140% !important;
        width: 400px !important;
        text-align: center !important;
        color: $main-text-color !important;
    }
}
.create-deal-timeline {
    .ant-steps-item-tail {
        margin-top: 3px;
    }
}

@keyframes resizeDot {
    0% {
        height: 15px;
        width: 15px;
    }
    50% {
        height: 35px;
        width: 35px;
    }
    100% {
        height: 15px;
        width: 15px;
    }
}
