.profile-details {
    &__profile-picture-container {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        border: 1px solid $border-color-input;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    &__profile-picture-icon {
        font-size: 80px;
        color: $font-color-input;
        margin-top: auto;
    }

    &__phone-input {
        border: none;
        height: 38px;

        &:hover {
            border: none;
            outline: none;
        }

        &:focus {
            border: none;
            outline: none;
        }
    }

    &__input-container {
        margin-top: 2px;
        border: 1px solid $border-color-input;
        border-radius: 4px;
        padding-left: 5px;
        height: 40px;
        padding-right: 5px;
        width: 100%;
    }

    &__phone-prefix {
        color: $font-color-input;
        font-size: 10px;
    }
}
