.infoIcon {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    line-height: 6px;
    text-align: center;
    vertical-align: middle;
    width: 8px;
    margin: 0px 5px 0 0;
}

.info {
    padding-left: 10px;
    text-transform: uppercase;

    &:hover {
        border: 5px;
        background-color: lightgrey;
        width: 90px;
        border-radius: 5px;
    }
}

.infoValue {
    font-size: 20px;
    padding-left: 15px;
    font-weight: 500;
}