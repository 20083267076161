.text-tiny {
    font-size: 0.7em;
}
/* ckeditor5-font/theme/fontsize.css */
.text-small {
    font-size: 0.85em;
}
/* ckeditor5-font/theme/fontsize.css */
.text-big {
    font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.text-huge {
    font-size: 1.8em;
}
