.data-link-transaction-page {
    &__navigation {
        &-content {
            .ant-tabs-nav {
                margin: 0 auto;
                max-width: 240px;
                padding-left: 0 !important;
                border-bottom: none !important;
            }
        }
    }
}
