.schedule {
    @at-root &__main-container {
        h3 {
            @extend %font-medium;
            color: $font-color-primary;
            font-size: 16px;
        }
    }

    &__container {
        border-radius: 8px;
        background-color: white;
        box-shadow: 0 0 20px 0 rgba(215, 222, 227, 0.39);
    }

    &__user {
        padding-top: 35px;

        &-card {
            max-width: 180px;
        }

        &-img {
            height: 80px;
            width: 90px;
            border-radius: 50%;
        }

        &-name {
            font-size: 18px;
            color: $font-color-secondary;
        }

        &-role {
            font-size: 10px;
            color: $font-color-primary;
        }

        &-time {
            @extend %font-medium;
            margin-top: 15px;
            font-size: 12px;
            color: $font-color-primary;
        }

        &-desc {
            margin-top: 20px;
            font-family: $font-family-secondary;
            font-size: 8px;
            color: $font-color-primary;
        }
    }

    &__events {
        border-radius: 50%;
        border: 1px solid rgba(72, 141, 255, 0.1);
        background-color: rgba(72, 141, 255, 0.1);
        position: absolute;
        top: 0;
        height: 50px;
        width: 50px;
        transform: translate(-25%, -25%);
    }

    &__red {
        color: $font-color-sun;
        cursor: pointer;
    }

    &__green {
        color: $border-color-radio-selected;
        cursor: pointer;
    }

    __events &__calendar-container {
        padding-top: 30px;
        margin-top: 30px;
        border-top: 1px solid $font-color-footer;
    }

    &__main {
        padding-top: 35px;
        border-left: 1px solid $font-color-date;
    }

    &__month {
        @extend %font-medium;
        font-size: 16px;
        font-family: $font-family-secondary;
        color: $font-color-secondary;
    }

    &__hours {
        @extend %font-medium;
        display: block;
        width: fit-content;
        padding: 20px 15px;
        font-size: 12px;
        font-family: $font-family-secondary;
        background: transparent;
        border-radius: 5px;
        color: $font-color-primary;
        border: 1px solid rgba(72, 141, 255, 0.1);
        cursor: pointer;
    }

    &__group {
        @extend %relative;
    }

    &__checkbox {
        @extend %absolute;
        appearance: none;
        height: auto;
        width: 100px;
    }

    &__calendar-right {
        padding-top: 8px;
    }

    &__btn-active {
        background: $bg-calendar-day-op;
        border: 1px solid $bg-calendar-day;
    }
}

.schedule__calendar-left {
    .ant-picker-calendar-mini {
        .ant-picker-calendar-header {
            flex-direction: row-reverse;
            margin-bottom: 20px;
            .ant-radio-group-small {
                display: none;
            }
        }
    }
}

.schedule__calendar-left {
    .ant-picker-calendar {
        .ant-picker-panel {
            .ant-picker-date-panel {
                .ant-picker-body {
                    .ant-picker-content {
                        thead {
                            tr {
                                th {
                                    color: $font-color-days;
                                    font-size: 10px;
                                }
                            }
                        }
                        tbody {
                            tr {
                                font-size: 12px;

                                .ant-picker-cell {
                                    height: 60px;
                                    width: 60px;
                                }

                                .ant-picker-cell-in-view {
                                    color: $font-color-schedule;
                                }

                                .ant-picker-cell-selected {
                                    position: relative;

                                    .ant-picker-cell-inner {
                                        position: relative;
                                        height: 50px;
                                        width: 50px;
                                        border-radius: 50% !important;
                                        background: $bg-calendar-day-op !important;
                                        border: 3px solid $bg-calendar-day;

                                        .ant-picker-calendar-date-value {
                                            position: absolute;
                                            left: 50%;
                                            top: 50%;
                                            transform: translate(-50%, -50%);
                                        }

                                        &::before {
                                            border: 0 !important;
                                        }
                                    }
                                    .ant-picker-calendar-date-content {
                                        .schedule__events {
                                            display: none !important;
                                        }
                                    }
                                }
                                .ant-picker-calendar-date-today {
                                    &::before {
                                        border: 0 !important;
                                    }
                                }
                            }

                            tr td:nth-child(6) {
                                color: $font-color-sat !important;
                            }

                            tr td:nth-child(7) {
                                color: $font-color-sun !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
